import { useHistory, useParams } from "react-router-dom";
import paymentSuccess from "./../../assets/paymentSuccess.gif";
import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./paymentSuccess.css";
import Pdf from "react-to-pdf";
import { pdfFromReact } from "generate-pdf-from-react-html";
import Logo from "../../assets/logo.png";
import { TextField } from "@mui/material";
import jsPDF from "jspdf";
import "./../payment-falier/paymentFail.scss"
import { getSubscriptedUserApi } from "../../Api";
import Swal from "sweetalert2";
import converter from "number-to-words"
const options = {
  orientation: "landscape",
  unit: "in",
};
const ref = React.createRef();

export default function PaymentSuccessPage() {
  const [invoiceDetails,setInvoiceDetails]=useState(true);
  const [error,setError]=useState(false);
  const [loading,setLoading]=useState(true);
  const {paymentId}=useParams();
  const history = useHistory();
  console.log(paymentId)
  useEffect(()=>{
    getDetails();
  },[])
  const getDetails=async()=>{
    try{
      const res=await getSubscriptedUserApi(paymentId);
      console.log(res.data.data.data)
      if(res.data.isSuccess){
        setInvoiceDetails(res.data.data.data)
        setLoading(false)
      }
      else{
        Swal.fire({
          icon: "error",
          title: "something went wrong please try again later.",
          showConfirmButton: true,
        });
        setLoading(false)
        setError(true)
        history.push("/")
      }
    }
    catch(error){
      Swal.fire({
        icon: "error",
        title: "something went wrong please try again later.",
        showConfirmButton: true,
      });
      setLoading(false)
      setError(true)
      console.log(error);
      history.push("/")
    }
    
  }
  return (
   <div style={{width:"100%"}} className="clclcl">
    <div className="payment-success-page-2">
      <div className="payment-success-img-container">
        <img src={paymentSuccess} className="payment-success-img" />
        <div className="payment-success-button-container">
          {" "}
          <Button
            className="payment-success-button"
            variant="contained"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Go Back To Home
          </Button>
          {!error && <Button
            className="payment-success-button"
            variant="contained"
            onClick={() => {
              const input = document.getElementById("payment-succes-page");
              const pdf = new jsPDF("p", "pt", "a1");
              pdf.html(input).then(() => {
                pdf.save("shivaayindia_invoice.pdf");
              });   
            }}
          >
            {loading ? <CircularProgress sx={{color:"white",width:"25px!important",height:"25px!important"}}/>:"Download Invoice"}
          </Button>}
        </div>
      </div>
    </div>
     <div><div className="payment-succes-page" id="payment-succes-page" ref={ref}>
    <div className="invoice-container">
      <div className="invoice-header">
        <img src={Logo} style={{ height: "60px" }} />
        <div className="invoice-header-company-details">
          <div>
            <p>Registered Address</p>
            <p>D-9/269A, Ambedkar Colony, Chattarpur Pahari</p>
            <p>New Delhi 110074 IN</p>
            <p>GSTNO :- 07AEQFS1413R1ZE</p>
          </div>
          <div>
            <p>Corporate Address</p>
            <p>
              D-9/269A, Ambedkar Colony, Chattarpur Pahari,New Delhi 110074
              INDIA
            </p>
          </div>
        </div>
        <p>
          In case of any queries/assistance,please{" "}
          <b>call us on 18003092214 / 01144759901</b> or write to us on{" "}
          <b>support@shivaayindia.com</b>
        </p>
        <div className="invoice-heading">
          <p>Tax Invoice</p>
          <p>Name of the Service Provider : SHIVAAY</p>
        </div>
        <p>
          This is to certify that Vehicle with the following details is
          covered under Assistance Program, as per the details defined under
          benefits, terms and condition of the program.
        </p>
      </div>
      <div className="invoice-certificate-vehicle-container">
        <div className="invoice-certificate">
          <div className="invoice-subheading">CERTIFICATE</div>
          <div className="invoice-lable-input">
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Certificte Number
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.certificteNumber ? invoiceDetails.certificteNumber :"-"}
            />
          </div>
          <div className="invoice-lable-input">
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Plan Name
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.subscriptionPlan ?invoiceDetails.subscriptionPlan:"----"}
            />
          </div>
          <div className="invoice-lable-input">
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Certificate Start Date
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.createdAt ? invoiceDetails.createdAt.slice(0,10) :"-"}
            />
          </div>
          <div className="invoice-lable-input">
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Certificate End Date
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.certificteEndDate ? invoiceDetails.certificteEndDate.slice(0,10) :"-"}
            />
          </div>
          <div className="invoice-lable-input">
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Place Of Supply
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.placeOfSupply ? invoiceDetails.placeOfSupply :"-"}
            />
          </div>
          <div className="invoice-lable-input">
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              SAC Code
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.sacCode ? invoiceDetails.sacCode :"-"}
            />
          </div>
        </div>
        <div className="invoice-vehicle">
          <div className="invoice-subheading">VEHICLE</div>
          <div className="invoice-lable-input">
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Vehicle Registration Number
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.vehicleNumber
 ? invoiceDetails.vehicleNumber
 :"-"}
            />
          </div>
          <div className="invoice-lable-input">
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Manufacturer
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.vehicleManufacturer
 ? invoiceDetails.vehicleManufacturer
 :"-"}
            />
          </div>
          <div className="invoice-lable-input">
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Model
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.vehicleModel ? invoiceDetails.vehicleModel :"-"}
            />
          </div>
          {/* <div className="invoice-lable-input">
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Variant
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.vehicleVariant ? invoiceDetails.vehicleVariant :"-"}
            />
          </div> */}
          <div className="invoice-lable-input">
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Engine Number
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.vehicleEngineNumber ? invoiceDetails.vehicleEngineNumber :"-"}
            />
          </div>
          <div className="invoice-lable-input">
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Chassis Number
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.vehicleChassisNumber ? invoiceDetails.vehicleChassisNumber :"-"}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "10px 20px", width: "100%" }}>
        <div className="invoice-heading">PERSONAL DETAILS</div>
      </div>
      <div className="invoice-persnol-details">
        <div style={{ width: "100%" }}>
          <div
            className="invoice-lable-input"
            style={{ border: "1px solid gray" }}
          >
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              First Name
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.firstName ? invoiceDetails.firstName :"-"}
            />
          </div>
          <div
            className="invoice-lable-input"
            style={{ border: "1px solid gray" }}
          >
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Last Name
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.lastName ? invoiceDetails.lastName :"-"}
            />
          </div>
          <div
            className="invoice-lable-input"
            style={{ border: "1px solid gray" }}
          >
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Mobile Number
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.phoneNo ? invoiceDetails.phoneNo :"-"}
            />
          </div>
          <div
            className="invoice-lable-input"
            style={{ border: "1px solid gray" }}
          >
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Email Address
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.email ? invoiceDetails.email :"-"}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div
            className="invoice-lable-input"
            style={{ border: "1px solid gray" }}
          >
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Address 1
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.address1 ? invoiceDetails.address1 :"-"}
            />
          </div>
          <div
            className="invoice-lable-input"
            style={{ border: "1px solid gray" }}
          >
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Address 2
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.address2 ? invoiceDetails.address2 :"-"}
            />
          </div>
          {/* <div
            className="invoice-lable-input"
            style={{ border: "1px solid gray" }}
          >
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              Address 3
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.sacCode ? invoiceDetails.sacCode :"-"}
            />
          </div> */}
          <div
            className="invoice-lable-input"
            style={{ border: "1px solid gray" }}
          >
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              State
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.addressState ? invoiceDetails.addressState :"-"}
            />
          </div>
          <div
            className="invoice-lable-input"
            style={{ border: "1px solid gray" }}
          >
            <p
              style={{
                borderRight: "1px solid gray",
                padding: "10px 0px",
                marginRight: "8px",
              }}
            >
              City
            </p>
            <input
              style={{
                width: "100%",
                border: "none",
                height: "100%",
                padding: "10px 0px",
                fontSize: "18px",
              }}
              value={invoiceDetails?.addressCity ? invoiceDetails.addressCity :"-"}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "10px 20px", width: "100%" }}>
        <div className="invoice-heading">PAYMENT DETAILS</div>
        <div
          className="invoice-lable-input"
          style={{ border: "1px solid gray" }}
        >
          <p
            style={{
              borderRight: "1px solid gray",
              padding: "10px 0px",
              marginRight: "8px",
            }}
          >
            Plan Amount
          </p>
          <input
            style={{
              width: "100%",
              border: "none",
              height: "100%",
              padding: "10px 0px",
              fontSize: "18px",
            }}
            value={invoiceDetails?.planAmount ? invoiceDetails.planAmount :"-"}
          />
        </div>
        <div
          className="invoice-lable-input"
          style={{ border: "1px solid gray" }}
        >
          <p
            style={{
              borderRight: "1px solid gray",
              padding: "10px 0px",
              marginRight: "8px",
            }}
          >
            Amount Of Tax IGST (18%)
          </p>
          <input
            style={{
              width: "100%",
              border: "none",
              height: "100%",
              padding: "10px 0px",
              fontSize: "18px",
            }}
            value={invoiceDetails?.gstAmount ? invoiceDetails.gstAmount :"-"}
          />
        </div>
        <div
          className="invoice-lable-input"
          style={{ border: "1px solid gray" }}
        >
          <p
            style={{
              borderRight: "1px solid gray",
              padding: "10px 0px",
              marginRight: "8px",
            }}
          >
            GST No. of the Service Recipient
          </p>
          <input
            style={{
              width: "100%",
              border: "none",
              height: "100%",
              padding: "10px 0px",
              fontSize: "18px",
            }}
            // value={invoiceDetails?.sacCode ? invoiceDetails.sacCode :"-"}
            value={"-----"}
          />
        </div>
        <div
          className="invoice-lable-input"
          style={{ border: "1px solid gray" }}
        >
          <p
            style={{
              borderRight: "1px solid gray",
              padding: "10px 0px",
              marginRight: "8px",
            }}
          >
            Total Amount (Including Tax)
          </p>
          <input
            style={{
              width: "100%",
              border: "none",
              height: "100%",
              padding: "10px 0px",
              fontSize: "18px",
            }}
            value={invoiceDetails?.totalAmount ? invoiceDetails.totalAmount:"___"}
          />
        </div>
        <div
          className="invoice-lable-input"
          style={{ border: "1px solid gray" }}
        >
          <p
            style={{
              borderRight: "1px solid gray",
              padding: "10px 0px",
              marginRight: "8px",
            }}
          >
            Amount In Words
          </p>
          <input
            style={{
              width: "100%",
              border: "none",
              height: "100%",
              padding: "10px 0px",
              fontSize: "18px",
            }}
            value={converter.toWords(Number(invoiceDetails?.totalAmount ? invoiceDetails.totalAmount:20))}
            // value={converter.toWords(20)}
          />
        </div>
      </div>
      <div style={{ padding: "10px 20px", width: "100%" }}>
        <div className="invoice-heading">
          PLAN FEATURES (Depanding on vehicle * )
        </div>
        <div className="invoice-plan-features-g">
          <b>Serial Number NIT442</b>
          <b>Plan Features</b>
          <b>NIT442</b>
        </div>
        <div className="invoice-plan-features-w">
          <b>1</b>
          <b>Towing of Vehicle on accident</b>
          <b>Y (Upto 25 km G 2 G)</b>
        </div>
        <div className="invoice-plan-features-g">
          <b>2</b>
          <b>Tyre Change</b>
          <b>Y</b>
        </div>
        <div className="invoice-plan-features-w">
          <b>3</b>
          <b>Arrangement of spare keys</b>
          <b>Y</b>
        </div>
        <div className="invoice-plan-features-g">
          <b>4</b>
          <b>Extraction or Removal of vehicle</b>
          <b>Y</b>
        </div>
        <div className="invoice-plan-features-w">
          <b>5</b>
          <b>Message relay to relatives/colleagues/emergency numbers</b>
          <b>Y</b>
        </div>
        <div className="invoice-plan-features-g">
          <b>6</b>
          <b>Fuel delivery</b>
          <b>Y</b>
        </div>
        <div className="invoice-plan-features-w">
          <b>7</b>
          <b>Hotel Accomodation</b>
          <b>Assistance</b>
        </div>
        <div className="invoice-plan-features-g">
          <b>8</b>
          <b>Proposed No. of Services</b>
          <b>4</b>
        </div>
        <div className="invoice-plan-features-w">
          <b>9</b>
          <b>Complimentary Personal Accident Insurance</b>
          <b>Y</b>
        </div>
      </div>
      <div style={{ padding: "10px 20px", width: "100%" }}>
        <div className="invoice-heading">PLAN FEATURES (In Details* )</div>
        <div className="invoice-subheading-container">
          <div className="invoice-subheading">
            1)Towing of Vehicle on Accident
          </div>
          <div className="invoice-subheading">2) Tyre Change</div>
        </div>
        <div className="invoice-subheading-containt">
          <p className="">
            In the event Covered Vehicle suffers an immobilizing break down
            due to a mechanical or electrical | fault or an accident which
            cannot be repaired on the spot, Shivaay roadside will assist in
            making | arrangement for the Vehicle to be towed to the nearest
            Authorised Service Center, using tow trucks | in the cities and
            corresponding covered area where available. (Free Towing To and
            From upto 25 KM){" "}
          </p>
          <p className="">
            In the event Covered Vehicle is immobilized due to a flat tyre,
            Shivaay roadside will assist the Insured by organizing for a
            vehicle technician to replace the flat tyre with the spare stepney
            tyre of the Vehicle at the location of breakdown. Shivaay roadside
            will bear labour cost and round-trip conveyance costs of the
            provider. Material/spare parts if required to repair the Vehicle
            (including repair of flat spare stepney tyre) will be borne by the
            Insured. In case the spare tyre is not available in the covered
            Vehicle, the flat tyre will be taken to the nearest flat tyre
            repair shop for repairs and re-attached to the Vehicle. All
            incidental charges for the same shall be borne by the
            Insured.( SERVICE AVAILABLE ONLY IN CASE OF ACCIDENT BREAKDOWN )
          </p>
        </div>

        <div className="" style={{ width:"100%",textAlign:"center"}}><img src={Logo} style={{ height: "60px" ,marginTop:"130px"}} /></div>
        <div className="invoice-subheading-container">
          <div className="invoice-subheading">
            3) Arrangement of spare keys
          </div>
          <div className="invoice-subheading">
            4) Extraction or Removal of vehicle
          </div>
        </div>
        <div className="invoice-subheading-containt">
          <p className="">
            If the keys of the covered vehicle are locked inside the vehicle,
            broken, lost, or misplaced, Shivaay | Roadside (upon the request
            of the Insured) will arrange for the forwarding of another set
            from his/her | place of residence or office by courier / in person
            by hand-delivery to the location of the vehicle | after receiving
            the requisite authorizations from the Insured with regards to the
            person designated to hand over the same to Shivaay roadside. The
            Insured may be requested to submit an identity proof | at the time
            of delivery of the keys.(SERVICE AVAILABLE ONLY IN CASE OF
            ACCIDENT BREAKDOWN)
          </p>
          <p className="">
            In event of vehicle being stuck in a ditch/pit/valley, Shivaay
            roadside will make the arrangement to get the vehicle retrieved
            and towed to the nearest authorized service center at no cost to
            the Insured.(Free Towing To and From upto 25 KM)
          </p>
        </div>
        <div className="invoice-subheading-container">
          <div className="invoice-subheading">
          5) Message relay to relatives/colleagues/emergency numbers
          </div>
          <div className="invoice-subheading">6) Arrangement of fuel</div>
        </div>
        <div className="invoice-subheading-containt">
          <p className="">Shivaay roadside will take charge of relaying urgent messages relating to the breakdown to the authorized workshop and/or service contacts. When requested, Shivaay roadside will relay urgent messages on behalf of the Insureds to a designated person of their choice.</p>
          <p className="">In the event Covered Vehicle runs out of fuel and hence is immobilized while on a trip, Shivaay Roadside will assist Insured by organizing for a Vehicle technician to supply emergency fuel (up to 5 ltrs on a chargeable basis) at the location of breakdown. Shivaay roadside will bear labour
and conveyance costs. The cost of the fuel will be borne by the Insured.</p>
        </div>
        <div className="invoice-subheading-container">
          <div className="invoice-subheading">
          7) Hotel Accomodation and Travel
          </div>
          <div className="invoice-subheading">8) No of services</div>
        </div>
        <div className="invoice-subheading-containt">
          <p className="">Shivaay roadside will provide the assistance to book the same</p>
          <p className="">Insured will get 4 service covered under 12 Months of contract</p>
        </div>
      </div>
      <div style={{ padding: "10px 20px", width: "100%" }}>
        <div className="invoice-heading">Terms and Conditions :</div>
        <div className="invoice-subheading-container">
          <div className="invoice-subheading">
          1) City Tax and Other Charges
          </div>
          <div className="invoice-subheading">2) Adverse Weather</div>
        </div>
        <div className="invoice-subheading-containt">
          <p className="">
          In case the vehicle is being towed from one state to another or from one city to another, any local tax or toll tax, like green tax in Delhi-NCR and etc. has to borne by Insured.
          </p>
          <p className="">
          On occasion of adverse weather conditions such as floods, heavy rain, thunder / lightening or | other external factors may affect our ability to provide services and it may become physically
impossible to assist you until the weather improves. During such times, our main priority will be to ensure that you and your passengers are taken to a place of safety; the recovery of your vehicle may not be possible until weather conditions permit.
          </p>
        </div>
        <div className="invoice-subheading-container">
          <div className="invoice-subheading">
          3) Coverage
          </div>
          <div className="invoice-subheading">
          | 4) Program Start Date
          </div>
        </div>
        <div className="invoice-subheading-containt">
          <p className="">
          The roadside assistance is a nationwide service provided to mobilize vehicle which has become disabled due to unexpected breakdown. While providing roadside assistance, we carry out temporary repairs and not regular maintenance which are normally carried out at authorized workshops.
          </p>
          <p className="">
          The date of commencement of coverage under the program. The program start date will be after 7 (SEVEN) days from the program purchase date.
          </p>
        </div>
        <div className="invoice-subheading-container">
          <div className="invoice-subheading">
          5) Program End Date
          </div>
          <div className="invoice-subheading">6) Un-located or Unattended Vehicle</div>
        </div>
        <div className="invoice-subheading-containt">
          <p className="">The coverage end date of the program. The Roadside Assistance Program shall be valid for a period of 12 (twelve) months from the Program start date as mentioned in the program certificate.</p>
          <p className="">In  case of a breakdown, you must be able to provide us with the correct location of your covered
| vehicle. Incorrect or incomplete information may cause a delay in provision of our services. You or an  authorized representative must be present with the vehicle at the agreed meeting place when
we arrive. If the vehicle is not attended when we arrive services cannot be provided.</p>
        </div>
        <div className="invoice-subheading-container">
          <div className="invoice-subheading">
          7) Covered Vehicle is off road
          </div>
          <div className="invoice-subheading">| 8) Cooling Period</div>
        </div>
        <div className="invoice-subheading-containt">
          <p className="">In case the covered vehicle is not on gazette, bitumen road and if there is a need to arrange
special equipment (any equipment other than standard towing equipment), the charges of such equipment are not part of the roadside assistance program. The charges for arrangements of
special equipment are to be borne by Insured. Insured should understand that in case of usage of special equipment’s covered vehicle might sustain certain direct or consequential damages which extracting the vehicle. Insured agrees that such damage shall be sole responsibility of the Insured
and AWP shall not be held liable for any such damages.</p>
          <p className="">That the Entitlements are available after 7 days, all the benefits under the roadside assistance | program became available 7 days after from the purchase date of the program.</p>
        </div>
        <div className="invoice-subheading-container">
          <div className="invoice-subheading">
          9) Accidental cases
          </div>
          <div className="invoice-subheading"> 10) External Factors</div>
        </div>
        <div className="invoice-subheading-containt">
          <p className="">In cases where the police authorities have registered an FIR or are in a process of registering an FIR or are investigating a case or have taken possession of the vehicle, Assistance services can be activated only after Police authorities/courts have given a clearance to the vehicle / have released the possession of vehicle. In such cases it is the duty of the Insured to obtain such clearances. AWP can take handover of the vehicle only after clearances have been obtained and the Insured / Insured’s representative is available to provide appropriate handover of the vehicle to the towing representative from the police authorities.</p>
          <p className="">We will take every effort to reach you once you make the call however the response time may vary | depending on, among other things, the breakdown location of the vehicle and the general demand | for roadside assistance at the time of your request is received.</p>
        </div>
      </div>
    </div>
    {/* <Pdf
      targetRef={ref}
      filename="shivaayIndia-invoice.pdf"
      options={options}
      x={1}
      y={1}
      scale={0.8}
    >
      {({ toPdf }) => (
        <button className="button" onClick={toPdf}>
          Pdf İndir
        </button>
      )}
    </Pdf> */}
    ;
  </div></div>
   </div>
  );
}
