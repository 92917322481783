import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect({handleChange,options}) {
  return (
    <Box sx={{ width: "100%",mt:"1px" ,mb:"5px"}}>
      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
        //   value={age}
        //   label="Age"
          onChange={handleChange}
        >{
            options.map((value)=><MenuItem value={value.key}>{value.value}</MenuItem>)
        }
        </Select>
      </FormControl>
    </Box>
  );
}