import React, { useState } from "react";
import "./menu.scss";
const Menu = ({ title, Icon, onClick, isButtonSelected }) => {
  const handleButtonClick = () => {
    onClick();
  };
  return (
    <div
      className="dashboard__menu-item"
      onClick={handleButtonClick}
      style={isButtonSelected ? { background: "#000", color: "#00D1FF" } : {}}
    >
      <div className="dashbord-menu-icon">{Icon}</div>
      <div className="dashbord-menu-icon-text">
        <p className="icon-text">{title}</p>
      </div>
    </div>
  );
};

export default Menu;
