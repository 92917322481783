import React, { Component } from "react";
import "./adminCustomerFullDetails.scss";
import { Button, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

export default class AdminCustomerFullDetails extends Component {
  render() {
    return (
      <div className="admin-customer-full-details">
        <div className="admin-customer-full-details-container">
          <div className="admin-customer-full-header">
            <h3>Customer Details</h3>
            <IconButton
              className="admin-customer-full-details-button"
              onClick={this.props.onCloseButtonPressed}
            >
              <Clear />
            </IconButton>
          </div>
          <div
            className="admin-customer-full-details-details"
            style={{ marginTop: "30px" }}
          >
            <span style={{ fontWeight: "600" }}>Customer Name:</span>{" "}
            {this.props.customer.customerName}
          </div>
          <div
            className="admin-customer-full-details-details"
            style={{ marginTop: "30px" }}
          >
            <span style={{ fontWeight: "600" }}>customerPhone:</span>{" "}
            {this.props.customer.customerPhone}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>customerRegNo:</span>{" "}
            {this.props.customer.customerRegNo}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>customerVehicleBrand:</span>{" "}
            {this.props.customer.customerVehicleBrand}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>deleveryDate:</span>{" "}
            {this.props.customer.deleveryDate}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>jobTime:</span>{" "}
            {this.props.customer.jobTime}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>reachingTime:</span>{" "}
            {this.props.customer.reachingTime}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>Case Date:</span>{" "}
            {this.props.customer.caseDate}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>jobTime:</span>{" "}
            {this.props.customer.jobTime}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>
              technicianVehicleReadings:
            </span>{" "}
            {this.props.customer.technicianVehicleReadings}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>technicianVehicleKM:</span>{" "}
            {this.props.customer.technicianVehicleKM}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>reportedFault:</span>{" "}
            {this.props.customer.reportedFault}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>caseStatus:</span>{" "}
            {this.props.customer.caseStatus}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>technicianLocation:</span>{" "}
            {this.props.customer.technicianLocation}
          </div>
        </div>
      </div>
    );
  }
}
