import React, { useState, useEffect } from "react";
import "./navbar.scss";
import { MdMenu } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import Logo from "../../assets/logo.png";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  const history = useHistory();
  const [currentNavigate, setCurrentNavigate] = useState("/");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onNavigationButtonPressed = (navigate) => {
    history.push(navigate);
    setCurrentNavigate(navigate);
    setIsMenuOpen(false);
  };

  return (
    <div className="nav">
      <div className="nav-header">
        <img
          src={Logo}
          className="nav-title"
          onClick={() => {
            onNavigationButtonPressed("/");
          }}
        />
        {/* <div className="nav-title">WebBrings</div> */}
      </div>
      <div className="nav-links">
        <a
          // href="/"
          style={
            currentNavigate === "/"
              ? {
                  borderBottom: "2px solid rgb(190, 110, 12)",
                  color: "rgb(190, 110, 12)",
                  cursor: "pointer",
                }
              : { cursor: "pointer" }
          }
          onClick={() => {
            onNavigationButtonPressed("/");
          }}
        >
          HOME
        </a>
        <a
          style={
            currentNavigate === "/about-us"
              ? {
                  borderBottom: "2px solid rgb(190, 110, 12)",
                  color: "rgb(190, 110, 12)",
                  cursor: "pointer",
                }
              : { cursor: "pointer" }
          }
          onClick={() => {
            onNavigationButtonPressed("/about-us");
          }}
        >
          ABOUT US
        </a>
        <a
          style={
            currentNavigate === "/car-breakdown"
              ? {
                  borderBottom: "2px solid rgb(190, 110, 12)",
                  color: "rgb(190, 110, 12)",
                  cursor: "pointer",
                }
              : { cursor: "pointer" }
          }
          onClick={() => {
            onNavigationButtonPressed("/car-breakdown");
          }}
        >
          CAR BREAKDOWNS
        </a>
        <a
          style={
            currentNavigate === "/bike-breakdown"
              ? {
                  borderBottom: "2px solid rgb(190, 110, 12)",
                  color: "rgb(190, 110, 12)",
                  cursor: "pointer",
                }
              : { cursor: "pointer" }
          }
          onClick={() => {
            onNavigationButtonPressed("/bike-breakdown");
          }}
        >
          BIKE BREAKDOWNS
        </a>
        <a
          style={
            currentNavigate === "/pay-services"
              ? {
                  borderBottom: "2px solid rgb(190, 110, 12)",
                  color: "rgb(190, 110, 12)",
                  cursor: "pointer",
                }
              : { cursor: "pointer" }
          }
          onClick={() => {
            onNavigationButtonPressed("/pay-services");
          }}
        >
          SUBSCRIPTION'S
        </a>
        <a
          style={
            currentNavigate === "/login"
              ? {
                  borderBottom: "2px solid rgb(190, 110, 12)",
                  color: "rgb(190, 110, 12)",
                  cursor: "pointer",
                }
              : { cursor: "pointer" }
          }
          onClick={() => {
            onNavigationButtonPressed("/login");
          }}
        >
          LOGIN
        </a>
        {/* <Button
          variant="contained"
          size="medium"
          className="navbar-login-button"
          onClick={() => {
            window.location.href = "/contact-us";
          }}
        >
          CONTACT US
        </Button> */}
      </div>
      <div
        className="menuBtn"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        {isMenuOpen ? (
          <RxCross2 size={30} color={"black"} />
        ) : (
          <MdMenu size={30} color={"black"} />
        )}
      </div>
      <div
        className="nav_menu"
        style={isMenuOpen ? { top: "70px" } : { top: "100vh" }}
      >
        <div className="menuNav-links">
          <a
            // href="/"
            style={
              currentNavigate === "/"
                ? {
                    borderBottom: "2px solid rgb(190, 110, 12)",
                    color: "rgb(190, 110, 12)",
                    cursor: "pointer",
                  }
                : { cursor: "pointer" }
            }
            onClick={() => {
              onNavigationButtonPressed("/");
            }}
          >
            HOME
          </a>
          <a
            style={
              currentNavigate === "/about-us"
                ? {
                    borderBottom: "2px solid rgb(190, 110, 12)",
                    color: "rgb(190, 110, 12)",
                    cursor: "pointer",
                  }
                : { cursor: "pointer" }
            }
            onClick={() => {
              onNavigationButtonPressed("/about-us");
            }}
          >
            ABOUT US
          </a>
          <a
            style={
              currentNavigate === "/car-breakdown"
                ? {
                    borderBottom: "2px solid rgb(190, 110, 12)",
                    color: "rgb(190, 110, 12)",
                    cursor: "pointer",
                  }
                : { cursor: "pointer" }
            }
            onClick={() => {
              onNavigationButtonPressed("/car-breakdown");
            }}
          >
            CAR BREAKDOWNS
          </a>
          <a
            style={
              currentNavigate === "/bike-breakdown"
                ? {
                    borderBottom: "2px solid rgb(190, 110, 12)",
                    color: "rgb(190, 110, 12)",
                    cursor: "pointer",
                  }
                : { cursor: "pointer" }
            }
            onClick={() => {
              onNavigationButtonPressed("/bike-breakdown");
            }}
          >
            BIKE BREAKDOWNS
          </a>
          <a
            style={
              currentNavigate === "/pay-services"
                ? {
                    borderBottom: "2px solid rgb(190, 110, 12)",
                    color: "rgb(190, 110, 12)",
                    cursor: "pointer",
                  }
                : { cursor: "pointer" }
            }
            onClick={() => {
              onNavigationButtonPressed("/pay-services");
            }}
          >
            PAY SERVICES
          </a>
          <a
            style={
              currentNavigate === "/contact-us"
                ? {
                    borderBottom: "2px solid rgb(190, 110, 12)",
                    color: "rgb(190, 110, 12)",
                    cursor: "pointer",
                  }
                : { cursor: "pointer" }
            }
            onClick={() => {
              onNavigationButtonPressed("/contact-us");
            }}
          >
            CONTACT US
          </a>
          {/* <a
            onClick={() => {
              onNavigationButtonPressed("/contact-us");
            }}
          >
            CONTACT US
          </a> */}
          <Button
            variant="contained"
            size="large"
            className="navbar-login-button"
            onClick={() => {
              onNavigationButtonPressed("/contact-us");
            }}
          >
            Log In
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
