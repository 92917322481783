import React, { Component } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./circleLoader.scss";

export default class CurcularLoader extends Component {
  render() {
    return (
      <div className="circlular-loader">
        <CircularProgress className="loader-curcle" />
      </div>
    );
  }
}
