import "./footer.scss";
import { RiFacebookFill, RiInstagramLine } from "react-icons/ri";
import { SiGooglemaps } from "react-icons/si";
import ReactWhatsapp from "react-whatsapp";
import { BsWhatsapp } from "react-icons/bs";
import { RxAvatar } from "react-icons/rx";
import { useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory();
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-media-container">
          <div className="footer-media">
            <a
              className="footer-media-one"
              target={"_blank"}
              href="https://www.facebook.com/shivaayrsa?mibextid=LQQJ4d"
            >
              <RiFacebookFill className="footer-media-one-icon" />
            </a>
            <a
              className="footer-media-one"
              target={"_blank"}
              href="https://www.instagram.com/shivaayassistance/?igshid=YmMyMTA2M2Y%3D"
            >
              <RiInstagramLine className="footer-media-one-icon" />
            </a>
            <a
              className="footer-media-one"
              target={"_blank"}
              href="https://www.google.com/maps/place/Shivaay+Roadside+Assistance/@28.5058972,77.1826304,17z/data=!3m1!4b1!4m6!3m5!1s0x390d1f0495584a47:0x95ce51c5de6867cf!8m2!3d28.5058972!4d77.1826304!16s%2Fg%2F11f5t_jm5r?hl=en-IN"
            >
              <SiGooglemaps className="footer-media-one-icon" />
            </a>
            <ReactWhatsapp
              number="8178562901"
              message="
              Hey,
              I would like to explore the unique features and advantages of your services 
              that set them apart from others in the industry. 
              It would be helpful to schedule a call at your convenience to 
              discuss these details further.
              "
              className="footer-media-one"
            >
              <BsWhatsapp className="footer-media-one-icon" size={22} />{" "}
            </ReactWhatsapp>
            {/* <div
              className="footer-media-one"
              onClick={() => {
                history.push("/login");
              }}
            >
              <RxAvatar className="footer-media-one-icon" size={30} />
            </div> */}
          </div>
          <div className="footer-contact-us">
            <strong>EMERGENCY CALL US : </strong> 9911822224 / 01144759901
          </div>
        </div>
        <div className="footer-copy-right-container">
          <h6 className="footer-copy-right-header">
            2022 Shivaay Roadside Assistance India ALL RIGHTS RESERVED
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Footer;
