import React from "react";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import "./textField.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const TextFieldWithBothIcon = ({
  error,
  errorMsg,
  label,
  type,
  firstIconName,
  onChange,
  onClickPasswordIcon,
  showPassword,
  placeholder,
  defalutValue,
  value,
}) => {
  return (
    <div className="text-field">
      <div className="input-label">{label}</div>
      <TextField
        sx={{
          width: "100%",
        }}
        defaultValue={defalutValue}
        error={error}
        placeholder={placeholder}
        helperText={error ? errorMsg : ""}
        type={type}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{firstIconName}</InputAdornment>
          ),
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default TextFieldWithBothIcon;
