import { Button, CircularProgress, TextField } from "@mui/material";
import React, { Component } from "react";
import "./createNewVendorModel.scss";
import { createTechnicianApi, createVendorDataApi, updateTechnicianApi, updateVendorDataApi } from "../../Api";
import Swal from "sweetalert2";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


export default class CreateNewVendorModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // registeredVehicles: this.props.selectedTechnicianToEdit?.registeredVehicles?this.props.selectedTechnicianToEdit.registeredVehicles:"",
      loading: false,
      name: this.props.selectedTechnicianToEdit.name
        ? this.props.selectedTechnicianToEdit.name
        : "",
      address: this.props.selectedTechnicianToEdit.address
        ? this.props.selectedTechnicianToEdit.address
        : "",
      key: this.props.selectedTechnicianToEdit.key
        ? this.props.selectedTechnicianToEdit.key
        : "",
    };
  }
  handleChange = (event) => {
    this.setState({ registeredVehicles: event })
    console.log(event)
  };
  onSaveOrEditButtonPressed = async (btnType) => {
    this.setState({ loading: true });
    if (btnType === "create") {
      if (this.state.name.length > 0) {
        const res = await createVendorDataApi({
          name: this.state.name,
          address: this.state.address,
          key: this.state.key,
          otherDetails: "other details",
          email: "email",
        }
        );
        if (res.isSuccess) {
          this.setState({ loading: false });
          Swal.fire({
            icon: "success",
            title: "Vendor is created successfully.",
            showConfirmButton: true,
          });
          this.props.onCreateNewVendorCloseButtonPressed();
        } else {
          this.setState({ loading: false });
          Swal.fire({
            icon: "error",
            title: "Someting went wrong please try again after some time.",
            showConfirmButton: true,
          });
        }
      } else {
        this.setState({ loading: false });
        Swal.fire({
          icon: "warning",
          title: "All fields are mandatory.",
          showConfirmButton: true,
        });
      }
    } else {
      if (this.state.name.length > 0) {
        const res = await updateVendorDataApi(
          {_id:this.props.selectedTechnicianToEdit._id,
            name:this.state.name,
            address:this.state.address,
            key:this.state.key,
          email:"email",
          otherDetails:"other details"
        }

        );
        if (res.isSuccess) {
          this.setState({ loading: false });
          Swal.fire({
            icon: "success",
            title: "Vendor is updated successfully.",
            showConfirmButton: true,
          });
          this.props.onCreateNewVendorCloseButtonPressed();
        } else {
          this.setState({ loading: false });
          Swal.fire({
            icon: "error",
            title: "Someting went wrong please try again after some time.",
            showConfirmButton: true,
          });
        }
      } else {
        this.setState({ loading: false });
        Swal.fire({
          icon: "warning",
          title: "All fields are mandatory.",
          showConfirmButton: true,
        });
      }
    }
  };
  render() {
    return (
      <div className="create-technician-model">
        <div className="create-technician-model-container">
          <h2 style={{ marginBottom: "20px" }}>
            {this.props.typeOfModel === "create"
              ? "Create New Vendor"
              : "Edit Vendor"}
          </h2>
          <p className="create-technician-model-textField-lable">
            Vendor Name
          </p>
          <TextField
            className="create-technician-model-textField"
            placeholder="Vendor Name"
            defaultValue={this.props.selectedTechnicianToEdit.name}
            onChange={(e) => {
              this.setState({ name: e.target.value });
            }}
          />
          <p className="create-technician-model-textField-lable">
            Vendor Address
          </p>
          <TextField
            className="create-technician-model-textField"
            placeholder="Vendor Address"
            defaultValue={this.props.selectedTechnicianToEdit.address}
            onChange={(e) => {
              this.setState({ address: e.target.value });
            }}
          />
          <p className="create-technician-model-textField-lable">
            Vendor Key Id
          </p>
          <TextField
            className="create-technician-model-textField"
            placeholder="Vendor Key Id"
            defaultValue={this.props.selectedTechnicianToEdit.key}
            onChange={(e) => {
              this.setState({ key: e.target.value });
            }}
          />
          {/* <Box sx={{ minWidth: "100%",marginTop:"15px" }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Vehicle's</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.registeredVehicles}
          label="Vehicle"
          defaultValue={this.state.registeredVehicles}
        >
          {this.props.registeredVehicles.map((vehicel)=>{return <MenuItem value={vehicel.deviceUniqueId} onClick={()=>{this.handleChange(vehicel.deviceUniqueId)}}>{vehicel.name}</MenuItem>})}
        </Select>
      </FormControl>
    </Box> */}
          <div className="create-technician-model-btn">
            <Button
              className="create-technician-model-closeBtn"
              variant="outlined"
              onClick={this.props.onCreateNewVendorCloseButtonPressed}
            >
              Close
            </Button>
            <Button
              className="create-technician-model-saveBtn"
              variant="contained"
              onClick={() => {
                this.onSaveOrEditButtonPressed(this.props.typeOfModel);
              }}
            >
              {this.state.loading ? (
                <CircularProgress
                  sx={{ height: "25px", width: "25px", color: "#fff" }}
                />
              ) : this.props.typeOfModel === "create" ? (
                "Save"
              ) : (
                "Edit"
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
