import React, { Component } from "react";
import LogoPng from "../../assets/logo.png";

export default class Logo extends Component {
  render() {
    const { fDirection } = this.props;
    // const {} = this.state;
    return (
      <div
        className="logo"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          gap: "10px",
        }}
      >
        <img src={LogoPng} style={{ width: "300px", height: "90px" }} />
      </div>
    );
  }
}
