import React, { Component } from "react";
import CustomizedTables from "../../componants/table-container/CustomizedTables";
import TablePaginationContainer from "../../small-componants/table-pagination/TablePaginationContainer";
import "./dashboard.scss";
import { getVisiterDataApi } from "../../Api";
import LinearProgress from "@mui/material/LinearProgress";
import DashboardDrawer from "../../componants/dashboardDrawer/DashboardDrawer";
import DashboardNavbar from "../../componants/dashboardNavbar/DashboardNavbar";
import { validateAdminApi } from "../../Api";
import CurcularLoader from "../../componants/loader/CurcularLoader";
import CustomerCareDashboard from "../../componants/customer-care-dashboard/CustomerCareDashboard";
import VendorDashboard from "../../componants/vendor-dashboard/VendorDashboard";
import AdminDashboard from "../../componants/admin-dashboard/AdminDashboard";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visiterDetails: [],
      error: false,
      pageSize: 10,
      pageNo: 0,
      visiterTablelData: [],
      isOpenDrawer: false,
      isValidUser: false,
      userType: "",
      adminDetails: "",
    };
  }
  componentDidMount() {
    // this.getVisiterDetailsApi(this.state.pageNo, this.state.pageSize);
    this.chekUserValidity();
  }
  chekUserValidity = async () => {
    this.setState({ loading: true });
    const admin = JSON.parse(localStorage.getItem("adminsData"));
    console.log(admin);
    if (admin) {
      this.setState({
        userType: admin.data.userType,
        adminDetails: admin.data,
      });
      const reponse = await validateAdminApi(
        admin.data.email,
        admin.data.userType,
        admin.data._id
      );
      if (reponse.isSuccess) {
        this.setState({ loading: false, isValidUser: true });
      } else {
        this.props.history.push("/login");
      }
    } else {
      this.props.history.push("/login");
      this.setState({ loading: false });
    }
  };
  // getVisiterDetailsApi = async (pageNo, pageSize) => {
  //   this.setState({ loading: true });
  //   const apiResponse = await getVisiterDataApi(pageSize, pageNo);
  //   if (apiResponse.isSuccess) {
  //     console.log(apiResponse);
  //     this.setState({ visiterDetails: apiResponse.data });
  //     this.setState({ visiterTablelData: apiResponse.data.visiterDetails });
  //     this.setState({ loading: false });
  //   } else {
  //     alert("something went wrong! please try again");
  //     this.setState({ loading: false });
  //   }
  // };
  // setSubscriptionTablePagination = (pageSize, pageNo) => {
  //   this.setState({ pageNo: pageNo, pageSize: pageSize });
  //   this.getVisiterDetailsApi(pageNo, pageSize);
  // };
  openDrawer = () => {
    this.setState({ isOpenDrawer: true });
    alert("function called");
  };
  render() {
    const { isValidUser, userType, adminDetails } = this.state;
    if (isValidUser) {
      if (userType === "admin") {
        return (
          <div className="dashboard-container">
            <AdminDashboard adminDetails={adminDetails} />
            {/* <DashboardDrawer adminDetails={adminDetails} /> */}
          </div>
        );
      } else if (userType === "care") {
        return (
          <div className="dashboard-container">
            <CustomerCareDashboard adminDetails={adminDetails} />
          </div>
        );
      } else if (userType === "vendor") {
        return (
          <div className="dashboard-container">
            <VendorDashboard adminDetails={adminDetails} />
          </div>
        );
      }
    } else {
      <CurcularLoader />;
    }
  }
}
