import React, { lazy } from "react";
import Navbar from "./componants/navbar-compo/Navbar";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import CurcularLoader from "./componants/loader/CurcularLoader";
import "./app.scss";
import LoginPage from "./screens/login-page/LoginPage";
import PaymentSuccessPage from "./small-componants/payment-success-page/PaymentSuccessPage";
import PaymentFailPage from "./small-componants/payment-falier/PaymentFail";
import LocationGetCus from "./screens/getLocation/LocationGetCus";
const HomeScreen = lazy(() => import("./screens/home-screen/HomeScreen"));
const AboutScreen = lazy(() => import("./screens/about-screen/AboutScreen"));
const Footer = lazy(() => import("./componants/footer-compo/Footer"));
const CarBreakdown = lazy(() => import("./screens/car-breakdown/CarBreakdown"));
const BikeBreakdown = lazy(() =>
  import("./screens/bike-breakdown/BikeBreakdown")
);
const PayServices = lazy(() => import("./screens/pay-services/PayServices"));
const ContactUs = lazy(() => import("./screens/contact-us/ContactUs"));
const Dashboard = lazy(() => import("./screens/dashboard-screen/Dashboard"));

function App() {
  return (
    <div className="App">
      <React.Suspense fallback={<CurcularLoader />}>
        <BrowserRouter>
          <Navbar />
          <Switch>
            <Route exact path={"/"} component={HomeScreen} />
            <Route exact path={"/about-us"} component={AboutScreen} />
            <Route exact path={"/car-breakdown"} component={CarBreakdown} />
            <Route exact path={"/bike-breakdown"} component={BikeBreakdown} />
            <Route exact path={"/pay-services"} component={PayServices} />
            <Route exact path={"/contact-us"} component={ContactUs} />
            <Route exact path={"/login"} component={LoginPage} />
            <Route exact path={"/dashboard"} component={Dashboard} />
            <Route exact path={"/shivaayindia/userlocation/:userId"} component={LocationGetCus} />
            <Route
              exact
              path={"/paymentSuccess/:paymentId"}
              component={PaymentSuccessPage}
            />
            <Route
              exact
              path={"/paymentFailer/:ref"}
              component={PaymentFailPage}
            />
          </Switch>
        </BrowserRouter>
      </React.Suspense>
    </div>
  );
}

export default App;
