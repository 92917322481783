import React, { useEffect, useState } from "react";
import "./careCustomerDetails.scss";
import TextFieldWithBothIcon from "../../small-componants/textField/TextFieldWithBothIcon";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Button, CircularProgress, InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";

import {
  AccessTime,
  CalendarMonth,
  CarCrashSharp,
  CarRepair,
  Diversity3Rounded,
  ImportContacts,
  MapSharp,
  NumbersOutlined,
  NumbersSharp,
  PendingActions,
  PhoneIphoneOutlined,
  PinDrop,
  SignalWifiStatusbarConnectedNoInternet4Rounded,
} from "@mui/icons-material";
import {
  createCustomer,
  getDriverDataApi,
  sendMessageToCustomerApi,
  getCustomerDataApi,
  getVendorDataApi,
  sendMessageToCustomerForLocationApi,
  createCustomerWhileSendingMessageApi,
  getSubsCustomerDataApi,
  getCustomerByIdApi,
  getAllRegisteredVehcleApi
} from "../../Api";
import Swal from "sweetalert2";
import { vehicleEventTypeArray } from "../../handler/vehicleProbleObj";
import MultipleSelect from "../../customMui/MultipleSelect";
import BasicSelect from "../../customMui/BasicSelect";
import BasicDateTimePicker from "../../customMui/BasicDateTimePicker";
import { caseStatus, vehicleServiceType } from "../../handler/vehicleArrays";

const label = { inputProps: { "aria-label": "Switch demo" } };
const CareCustomerDetails = ({ selectedCustomer }) => {
  const [screenType, setScreenType] = useState("CustomerDetails");
  // const [screenType, setScreenType] = useState("TechnicianDetails");
  const [customerDetails, setCustomerDetails] = useState(
    selectedCustomer
      ? selectedCustomer
      : { customerName: "", customerPhone: "", vendorCaseId: "" }
  );
  const [driverDetails, setDriverDetails] = useState([]);
  const [customerApiDetails, setCustomerApiDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDriverDetails, setSelectedDriverDetails] = useState(undefined);
  const [vendorDetails, setVendorDetails] = useState([]);
  const [seletedVendor, setSeletedVendor] = useState(undefined);
  const [errorType, setErrorType] = useState("none");
  const [error, setError] = useState("none");
  const [caseId, setCaseId] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const [apiLoadingType, setApiLoadingType] = useState("none");
  const [locationSetByCus, setLocationSetByCus] = useState(false);
  const [getLocationCallRecusive, setGetLocationCallRecusivew] =
    useState(false);
  const [customerLocationDetails, setCustomerLocationDetails] = useState();
  var getLocationCallRecusiveSimpel = false;
  useEffect(() => {
    getDriverDetails();
    getCustomerDetails();
    getVendorDetails();
  }, []);
  const getVendorDetails = async () => {
    const response = await getVendorDataApi();
    console.log("vendorData", response.data.vendorRes);
    if (response.isSuccess) {
      setVendorDetails(response.data.vendorRes);
      console.log(driverDetails);
    } else {
      Swal.fire({
        icon: "error",
        title: "something went wrong please try again later.",
        showConfirmButton: true,
      });
    }
  };
  const getAllVehicleFromVehcileApi=async()=>{
    const response =await getAllRegisteredVehcleApi();
  }
  const setGetLocationCallRecusive = (value) => {
    setGetLocationCallRecusivew(value);
    getLocationCallRecusiveSimpel = value;
  };
  const generateCaseNumber = (increment) => {
    var currentDate = new Date();
    var day = ("0" + currentDate.getDate()).slice(-2);
    var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    var year = currentDate.getFullYear().toString().slice(-2);
    var lastSixDigits = ("000000" + increment).slice(-6);

    var generatedNumber = day + month + year + lastSixDigits;
    return generatedNumber;
  };
  const handleChangeVendorSelectMenu = (vendor) => {
    setSeletedVendor(vendor);
  };
  const getCustomerDetails = async () => {
    const response = await getCustomerDataApi();
    console.log(response);
    if (response.isSuccess) {
      setCustomerApiDetails(response.data.data);
      setCaseId(generateCaseNumber(response.data.data.length + 1));
      setCustomerDetails({
        ...customerDetails,
        ["vendorCaseId"]: generateCaseNumber(response.data.data.length + 1),
      });
      console.log(customerDetails);
    } else {
      Swal.fire({
        icon: "error",
        title: "something went wrong please try again later.",
        showConfirmButton: true,
      });
    }
  };
  const getDriverDetails = async () => {
    const response = await getDriverDataApi();
    console.log(response);
    if (response.isSuccess) {
      setDriverDetails(response.data.data);
      console.log(driverDetails);
    } else {
      Swal.fire({
        icon: "error",
        title: "something went wrong please try again later.",
        showConfirmButton: true,
      });
    }
  };
  const getLocationUntilUserSubmitHisLocation = async (customerId) => {
    console.log(
      "calling.. until location find by the api.",
      getLocationCallRecusive
    );
    if (getLocationCallRecusiveSimpel) {
      const res = await getCustomerByIdApi(customerId);
      console.log(
        "calling.. until location find by the api long and let.",
        res.data.data.customerLocationDetails
      );
      if (res.isSuccess) {
        if (
          res.data.data.customerLocationDetails?.longitude &&
          res.data.data.customerLocationDetails?.latitude
        ) {
          Swal.fire({
            icon: "success",
            title: "Location is given by customer.",
            showConfirmButton: true,
          });
          setLocationSetByCus(true);
          setGetLocationCallRecusive(false);
          setCustomerLocationDetails(res.data.data.customerLocationDetails);
        } else {
          setTimeout(() => {
            getLocationUntilUserSubmitHisLocation(customerId);
          }, 5000);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "something went wrong please try again later.",
          showConfirmButton: true,
        });
        setLocationSetByCus(false);
        setGetLocationCallRecusive(false);
      }
    }
  };
  const onSendSmsForLocationButtonPressed = async () => {
    if (
      customerDetails?.customerName.length > 1 &&
      customerDetails?.customerPhone.length === 10 &&
      seletedVendor !== undefined &&
      customerDetails.vendorCaseId.length > 1
    ) {
      setApiLoading(true);
      setApiLoadingType("sentMsg");
      const res = await createCustomerWhileSendingMessageApi({
        customerName: customerDetails.customerName,
        customerPhone: customerDetails.customerPhone,
        vendorCaseId: customerDetails.vendorCaseId,
        vendor: seletedVendor,
        caseId: caseId,
      });
      console.log("resformUI", res);
      // if(res.isSuccess && response.return){
      if (res.isSuccess) {
        const response = await sendMessageToCustomerForLocationApi(
          customerDetails.customerName,
          customerDetails.customerPhone,
          `https://shivaayindia.com`,
          `/shivaayindia/userlocation/`,
          `${res.data._id} `
        );
        if (response.return) {
          // if (true) {
          Swal.fire({
            icon: "success",
            title: "Message sent to customer successfully.",
            showConfirmButton: true,
          });
          setApiLoading(false);
          setApiLoadingType("");
          setGetLocationCallRecusive(true);
          getLocationUntilUserSubmitHisLocation(res.data._id);
        } else {
          Swal.fire({
            icon: "error",
            title: "something went wrong please try again later.",
            showConfirmButton: true,
          });
          setApiLoading(false);
          setApiLoadingType("");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "something went wrong please try again later.",
          showConfirmButton: true,
        });
        setApiLoading(false);
        setApiLoadingType("");
      }
    } else {
      if (customerDetails?.customerName.length <= 0) {
        setErrorType("cusName");
        setError(true);
      } else if (customerDetails.vendorCaseId.length <= 0) {
        setErrorType("vendorCaseId");
        setError(true);
      } else if (customerDetails.customerPhone.length <= 0) {
        setErrorType("cusPhone");
        setError(true);
      } else if (seletedVendor === undefined) {
        setErrorType("selVendor");
        setError(true);
      } else {
        setErrorType("caseId");
        setError(true);
      }
    }
  };
  const onNextButtonPressed = async (val) => {
    setLoading(true);
    console.table(customerDetails);
    if (screenType === "CustomerDetails") {
      if (
        // customerDetails.caseId &&
        customerDetails.customerName
        // customerDetails.customerPhone &&
        // customerDetails.cutomerLocation &&
        // customerDetails.vehicleBrand &&
        // customerDetails.reportedFault &&
        // customerDetails.caseStatus
      ) {
        const response = await createCustomer(customerDetails);
        console.log(response);
        if (response) {
          setLoading(false);
          setScreenType("TechnicianDetails");
        } else {
          Swal.fire({
            icon: "error",
            title: "something went wrong please try again later.",
            showConfirmButton: true,
          });
          setLoading(false);
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "All fields are mandatory.",
          showConfirmButton: true,
        });
        setLoading(false);
      }
    } else if (screenType === "TechnicianDetails") {
      if (
        // customerDetails.caseId &&
        customerDetails.customerName &&
        customerDetails.customerPhone &&
        customerDetails.cutomerLocation &&
        customerDetails.vehicleBrand &&
        customerDetails.reportedFault &&
        customerDetails.caseStatus &&
        customerDetails.technicianName &&
        customerDetails.technicianJobNo &&
        customerDetails.technicianLocation &&
        customerDetails.technicianVehicleKM &&
        customerDetails.technicianVehicleReadings &&
        customerDetails.caseDate
      ) {
        let messageResponse;
        if (customerDetails._id) {
          messageResponse = { return: true };
        } else {
          messageResponse = await sendMessageToCustomerApi(customerDetails);
        }
        const response = await createCustomer(customerDetails);
        console.log(response);
        console.log(messageResponse);
        if (response.isSuccess && messageResponse.return) {
          setScreenType("CaseDetails");
          setLoading(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "something went wrong please try again later.",
            showConfirmButton: false,
          });
          setLoading(false);
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "All fields are mandatory.",
          showConfirmButton: true,
        });
        setLoading(false);
      }
    } else if (screenType === "CaseDetails") {
      if (
        // customerDetails.caseId &&
        customerDetails.customerName &&
        customerDetails.customerPhone &&
        customerDetails.cutomerLocation &&
        customerDetails.vehicleBrand &&
        customerDetails.reportedFault &&
        customerDetails.caseStatus &&
        customerDetails.technicianName &&
        customerDetails.technicianJobNo &&
        customerDetails.technicianLocation &&
        customerDetails.technicianVehicleKM &&
        customerDetails.technicianVehicleReadings &&
        customerDetails.jobTime &&
        customerDetails.deleveryDate &&
        customerDetails.otherDetails
      ) {
        const response = await createCustomer(customerDetails);
        console.log(response);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Details are saved successfully.",
            showConfirmButton: true,
          });
          setLoading(false);
          setCustomerDetails({});
          setScreenType("CustomerDetails");
        } else {
          Swal.fire({
            icon: "error",
            title: "something went wrong please try again later.",
            showConfirmButton: true,
          });
          setLoading(false);
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "All fields are mandatory.",
          showConfirmButton: true,
        });
        setLoading(false);
      }
    } else {
      setScreenType("CustomerDetails");
      setLoading(false);
    }
  };
  const onBackButtonPressed = () => {
    console.log(customerDetails.caseId);
    if (screenType === "CustomerDetails") {
      setScreenType("CustomerDetails");
    } else if (screenType === "TechnicianDetails") {
      setScreenType("CustomerDetails");
    } else if (screenType === "CaseDetails") {
      setScreenType("TechnicianDetails");
    } else {
      setScreenType("CustomerDetails");
    }
  };
  const onSelectedDriverChange = (e) => {
    console.log(e);
    setSelectedDriverDetails(e);
    setCustomerDetails({
      ...customerDetails,
      ["technicianPhone"]: e.phoneNo,
      ["technicianName"]: e.name,
      ["technicianJobNo"]: e.jobId,
    });
    // setCustomerDetails({ ...customerDetails });
  };
  const handleChangeInTextField = (event, name) => {
    console.log(event.target.value);
    setCustomerDetails({ ...customerDetails, [name]: event.target.value });
    // this.setState({
    //   organizationSignUpDetails: {
    //     ...this.state.organizationSignUpDetails,
    //     [name]: event.target.value,
    //   },
    // });
    console.log(customerDetails);
  };
  return (
    <div className="care-customer">
      <h1 className="care-customer-heading">
        {screenType === "CustomerDetails" && "Customer Details"}
        {screenType === "TechnicianDetails" && "Technician Details"}
        {screenType === "CaseDetails" && "Case Details"}
      </h1>
      {screenType === "CustomerDetails" && (
        <div className="care-customer-form">
          <div className="care-customer-form-column">
            <TextFieldWithBothIcon
              label="Case Id #"
              firstIconName={<NumbersOutlined />}
              onChange={(e) => {
                setCaseId(caseId);
              }}
              value={caseId}
              placeholder="Case Id#"
              error={error && errorType === "caseId"}
              errorMsg="please enter valid  case id"
            />
            {/* <TextFieldWithBothIcon
              label="Customer Mobile Number"
              firstIconName={<PhoneIphoneOutlined />}
              onChange={(e) => {
                handleChangeInTextField(e, "customerPhone");
              }}
              value={customerDetails.customerPhone}
              placeholder="Customer Mobile Number"
            /> */}
          </div>
          <div className="care-customer-form-column" style={{ width: "100%" }}>
            <TextFieldWithBothIcon
              label="Vendor case Id #"
              firstIconName={<NumbersOutlined />}
              onChange={(e) => {
                handleChangeInTextField(e, "vendorCaseId");
              }}
              error={error && errorType === "vendorCaseId"}
              errorMsg="please enter valid vendor case id"
              value={caseId}
              placeholder="Vendor case Id#"
            />
            <Box sx={{ minWidth: "100%", marginTop: "15px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Vendor
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={seletedVendor}
                  label="Vehicle"
                  defaultValue={seletedVendor}
                  error={error && errorType === "selVendor"}
                >
                  {vendorDetails.map((vendor) => {
                    return (
                      <MenuItem
                        value={vendor}
                        onClick={() => {
                          handleChangeVendorSelectMenu(vendor);
                        }}
                      >
                        {vendor.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="care-customer-form-column">
            <TextFieldWithBothIcon
              label="Customer Name"
              firstIconName={<AccountBoxIcon />}
              onChange={(e) => {
                handleChangeInTextField(e, "customerName");
              }}
              value={customerDetails.customerName}
              placeholder="Customer Name"
              error={error && errorType === "cusName"}
              errorMsg="Please enter a valid name"
            />
            <TextFieldWithBothIcon
              label="Customer Mobile Number"
              firstIconName={<PhoneIphoneOutlined />}
              onChange={(e) => {
                handleChangeInTextField(e, "customerPhone");
              }}
              value={customerDetails.customerPhone}
              placeholder="Customer Mobile Number"
              error={error && errorType === "cusPhone"}
              errorMsg="mobile number should not contain more then 10 charcters"
            />
          </div>
          <div
            className="care-customer-form-column"
            style={{ margin: "20px 0px", padding: "0px 20px" }}
          >
            <p>
              Sent message to get location from customer.{" "}
              {locationSetByCus &&
                `... longitude: ${customerLocationDetails.longitude}   latitude: ${customerLocationDetails.latitude}`}
            </p>
            <div>
              <Button
                variant="contained"
                onClick={onSendSmsForLocationButtonPressed}
              >
                {apiLoading && apiLoadingType === "sentMsg"
                  ? "Loading..."
                  : "Send Message"}
              </Button>{" "}
              <Switch
                {...label}
                checked={getLocationCallRecusive}
                disabled={!getLocationCallRecusive}
                onClick={() => {
                  setGetLocationCallRecusive(!getLocationCallRecusive);
                }}
              />
            </div>
          </div>
          <div className="care-customer-form-column">
            <TextFieldWithBothIcon
              label="Customer Vehicle Brand / Model"
              firstIconName={<CarCrashSharp />}
              onChange={(e) => {
                handleChangeInTextField(e, "vehicleBrand");
              }}
              value={customerDetails.vehicleBrand}
              placeholder="Customer Vehicel Brand / Model"
            />
            <TextFieldWithBothIcon
              label="Customer Vehicle Registration No."
              firstIconName={<CarRepair />}
              onChange={(e) => {
                handleChangeInTextField(e, "customerRegNo");
              }}
              value={customerDetails.customerRegNo}
              placeholder="Customer Vehicle Registration No"
            />
          </div>
          <div className="care-customer-form-column">
            <TextFieldWithBothIcon
              label="Customer Reported Fault"
              firstIconName={<Diversity3Rounded />}
              onChange={(e) => {
                handleChangeInTextField(e, "reportedFault");
              }}
              value={customerDetails.reportedFault}
              placeholder="Customer Reported Fault"
            />
            <TextFieldWithBothIcon
              label="Customer Location"
              firstIconName={<PinDrop />}
              onChange={(e) => {
                handleChangeInTextField(e, "cutomerLocation");
              }}
              value={customerDetails.cutomerLocation}
              placeholder="Customer Location"
            />
          </div>
          <div className="care-customer-form-column">
            <div>
              <p>Event Type</p>
              <MultipleSelect onChange={() => {}} />
            </div>
            <div>
              <p>Service Type</p>
              <BasicSelect handleChange={()=>{}} options={vehicleServiceType}/>
            </div>
          </div>
          <div className="care-customer-form-column">
            {/* <TextFieldWithBothIcon
              label="Date/Time"
              firstIconName={<CalendarMonth />}
              onChange={(e) => {
                handleChangeInTextField(e, "caseDate");
              }}
              defaultValue={customerDetails.caseDate}
              value={customerDetails.caseDate}
              placeholder="Case Date"
            /> */}
            <div>
              <p>Case Date/Time</p>
              <BasicDateTimePicker />
            </div>
            {/* <TextFieldWithBothIcon
              label="Case Status"
              firstIconName={<SignalWifiStatusbarConnectedNoInternet4Rounded />}
              onChange={(e) => {
                handleChangeInTextField(e, "caseStatus");
              }}
              value={customerDetails.caseStatus}
              placeholder="Case Status"
            /> */}
            <div>
              <p>Case Status</p>
              <BasicSelect handleChange={()=>{}} options={caseStatus}/>
            </div>
          </div>
          <div className="care-customer-form-column">
            <Button
              variant="outlined"
              className="care-customer-form-button-back"
              onClick={onBackButtonPressed}
            >
              Back
            </Button>
            <Button
              variant="contained"
              className="care-customer-form-button-next"
              onClick={onNextButtonPressed}
            >
              {loading ? <CircularProgress sx={{ color: "#fff" }} /> : "Next"}
            </Button>
          </div>
        </div>
      )}
      {screenType === "TechnicianDetails" && (
        <div className="care-customer-form">
          {/* <div className="care-customer-form-column"> */}
          <p>Select Technician</p>
          <Select
            id="demo-select-small"
            value={selectedDriverDetails}
            sx={{ width: "100%", margin: "5px 0px" }}
            placeholder="Select Technician Name"
            defaultValue="Select Technician Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBoxIcon />
                </InputAdornment>
              ),
            }}
          >
            {driverDetails.length > 0 ? (
              driverDetails.map((driver, index) => {
                return (
                  <MenuItem
                    value={driver}
                    key={driver._id}
                    onClick={() => {
                      onSelectedDriverChange(driver);
                    }}
                  >
                    {driver.name} {}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem
                value={"Null"}
                key={1}
                onClick={() => {
                  // onSelectedDriverChange(driver);
                }}
              >
                No Driver Added to Database
              </MenuItem>
            )}
          </Select>
          {/* </div> */}
          <div className="care-customer-form-column">
            <TextFieldWithBothIcon
              label="Technician Name"
              placeholder="Technician Name"
              firstIconName={<AccountBoxIcon />}
              value={customerDetails.technicianName}
              onChange={(e) => {
                handleChangeInTextField(e, "technicianName");
              }}
            />
            <TextFieldWithBothIcon
              label="Technician Mobile Number"
              placeholder="Technician Mobile Number"
              firstIconName={<PhoneIphoneOutlined />}
              onChange={(e) => {
                handleChangeInTextField(e, "technicianPhone");
              }}
              value={customerDetails.technicianPhone}
            />
          </div>
          <div className="care-customer-form-column">
            <TextFieldWithBothIcon
              label="Technician Vehicle KM"
              firstIconName={<CarCrashSharp />}
              onChange={(e) => {
                handleChangeInTextField(e, "technicianVehicleKM");
              }}
              value={customerDetails.technicianVehicleKM}
              placeholder="Technician Vehicle KM"
            />
            <TextFieldWithBothIcon
              label="Technician Vehicle Readings"
              firstIconName={<AccessTime />}
              onChange={(e) => {
                handleChangeInTextField(e, "technicianVehicleReadings");
              }}
              value={customerDetails.technicianVehicleReadings}
              placeholder="Technician Vehicle Readings"
            />
          </div>
          <div className="care-customer-form-column">
            <TextFieldWithBothIcon
              label="Technician Job Id"
              firstIconName={<Diversity3Rounded />}
              onChange={(e) => {
                handleChangeInTextField(e, "technicianJobNo");
              }}
              value={customerDetails.technicianJobNo}
              placeholder="Technician Job No"
            />
            <TextFieldWithBothIcon
              label="Technician Location"
              firstIconName={<PinDrop />}
              onChange={(e) => {
                handleChangeInTextField(e, "technicianLocation");
              }}
              value={customerDetails.technicianLocation}
              placeholder="Technician Location"
            />
          </div>
          <div className="care-customer-form-column">
            <TextFieldWithBothIcon
              label="Case Status"
              firstIconName={<SignalWifiStatusbarConnectedNoInternet4Rounded />}
              onChange={(e) => {
                handleChangeInTextField(e, "caseStatus");
              }}
              value={customerDetails.caseStatus}
              placeholder="Case Status"
            />
            <TextFieldWithBothIcon
              label="Technician Reaching Time"
              firstIconName={<AccessTime />}
              onChange={(e) => {
                handleChangeInTextField(e, "reachingTime");
              }}
              value={customerDetails.reachingTime}
              placeholder="Technician Reaching Time"
            />
          </div>
          <div className="care-customer-form-column">
            <Button
              variant="outlined"
              className="care-customer-form-button-back"
              onClick={onBackButtonPressed}
            >
              Back
            </Button>
            <Button
              variant="contained"
              className="care-customer-form-button-next"
              onClick={onNextButtonPressed}
            >
              {loading ? <CircularProgress sx={{ color: "#fff" }} /> : "Next"}
            </Button>
          </div>
        </div>
      )}
      {screenType === "CaseDetails" && (
        <div className="care-customer-form">
          <div className="care-customer-form-column">
            <TextFieldWithBothIcon
              label="Technician Job Time"
              firstIconName={<Diversity3Rounded />}
              onChange={(e) => {
                handleChangeInTextField(e, "jobTime");
              }}
              value={customerDetails.jobTime}
              placeholder="Technician Job Time"
            />
            <TextFieldWithBothIcon
              label="Technician Reaching Time"
              firstIconName={<AccessTime />}
              onChange={(e) => {
                handleChangeInTextField(e, "reachingTime");
              }}
              value={customerDetails.reachingTime}
              placeholder="Technician Reachable Time"
            />
          </div>
          <div className="care-customer-form-column">
            <TextFieldWithBothIcon
              label="Distributor Delevery Date"
              firstIconName={<CalendarMonth />}
              onChange={(e) => {
                handleChangeInTextField(e, "deleveryDate");
              }}
              value={customerDetails.deleveryDate}
              placeholder="Distributor Delevery Date"
            />
            <TextFieldWithBothIcon
              label="Case Status"
              firstIconName={<SignalWifiStatusbarConnectedNoInternet4Rounded />}
              onChange={(e) => {
                handleChangeInTextField(e, "caseStatus");
              }}
              value={customerDetails.caseStatus}
              placeholder="Case Status"
            />
          </div>
          <div className="care-customer-form-column">
            <TextFieldWithBothIcon
              label="Other Details"
              firstIconName={<PendingActions />}
              onChange={(e) => {
                handleChangeInTextField(e, "otherDetails");
              }}
              value={customerDetails.otherDetails}
              placeholder="Other Details"
            />
          </div>

          <div className="care-customer-form-column">
            <Button
              variant="outlined"
              className="care-customer-form-button-back"
              onClick={onBackButtonPressed}
            >
              Back
            </Button>
            <Button
              variant="contained"
              className="care-customer-form-button-next"
              onClick={onNextButtonPressed}
            >
              {loading ? <CircularProgress sx={{ color: "#fff" }} /> : "Submit"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CareCustomerDetails;
