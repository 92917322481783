export const logOutUser = () => {
  window.location.href = "/";
  window.location.reload();
  localStorage.removeItem("adminsData");
  document.cookie =
    "adminToken" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  console.log("logOut function called");
};

export const getDataFromLocal = () => {
  const admin = JSON.parse(localStorage.getItem("adminsData"));
  console.log(admin);
  if (admin) {
    return admin;
  } else {
    logOutUser();
  }
};
