import React, { Component } from "react";
import "./adminDashboard.scss";
import GroupsIcon from "@mui/icons-material/Groups";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Menu from "../../small-componants/menu/Menu";
import Header from "../../componants/header/Header";
import Logo from "../../small-componants/logo/Logo";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LanguageIcon from "@mui/icons-material/Language";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import CustomerHistory from "../customer-history/CustomerHistory";
import CareCustomerDetails from "../care-customer-details/CareCustomerDetails";
import TechnicianHistory from "../technician-detals/TechnicianHistory";
import AdminSubscriptionCustomer from "../adash-subscription/AdminSubscriptionCustomer";
import VendorMenu from "../vendor-menu/VendorMenu";
export default class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndexType: 0,
      customerToEdit: "",
    };
  }
  onMenuChangeButtonPressed = (val) => {
    this.setState({ activeIndexType: val, customerToEdit: "" });
  };
  onEditCustomerButtonPressed = (customer) => {
    this.setState({ customerToEdit: customer, activeIndexType: 1 });
  };
  render() {
    const { activeIndexType } = this.state;
    return (
      <div className="dashbord">
        <div className="dashbord-container">
          <div className="dashbord-menu-container">
            <div className="dashbord-logo">
              <Logo fDirection="row" />
            </div>
            <div className="dashbord-menu-wrap">
              <Menu
                title="Customer's"
                onClick={() => {
                  this.onMenuChangeButtonPressed(0);
                }}
                Icon={<SupportAgentIcon />}
                isButtonSelected={activeIndexType === 0 ? true : false}
              />
              <Menu
                title="Add or Update Customer"
                onClick={() => {
                  this.onMenuChangeButtonPressed(1);
                }}
                Icon={<SupportAgentIcon />}
                isButtonSelected={activeIndexType === 1 ? true : false}
              />

              <Menu
                title="Technicians"
                onClick={() => {
                  this.onMenuChangeButtonPressed(2);
                }}
                Icon={<PeopleAltIcon />}
                isButtonSelected={activeIndexType === 2 ? true : false}
              />
              <Menu
                title="Subscription Customer"
                onClick={() => {
                  this.onMenuChangeButtonPressed(3);
                }}
                Icon={<SupervisedUserCircleIcon />}
                isButtonSelected={activeIndexType === 3 ? true : false}
              />
              <Menu
                title="Vendor Details"
                onClick={() => {
                  this.onMenuChangeButtonPressed(4);
                }}
                Icon={<LanguageIcon />}
                isButtonSelected={activeIndexType === 4 ? true : false}
              />
              <Menu
                title="Drivers Location"
                onClick={() => {
                  this.onMenuChangeButtonPressed("driver");
                }}
                Icon={<MinorCrashIcon />}
                isButtonSelected={activeIndexType === 5 ? true : false}
              />
            </div>
            {/* <div className="dashbord-menu-logoutButton"> </div> */}
          </div>
          <div className="dashbord-active-index">
            <div className="dashbord-active-index-header">
              <Header
                onChangePasswordButtonPressed={
                  // this.onChangePasswordButtonPressed
                  undefined
                }
              />
            </div>
            <div className="dashbord-active-index-container">
              {/* {this.state.showChangePasswordScreen && (
                <ChangePassword
                  adminToken={this.props.adminToken.adminToken}
                  onChangePasswordCancelButtonPressed={
                    this.onChangePasswordCancelButtonPressed
                  }
                />
              )} */}
              {activeIndexType === 0 && (
                <CustomerHistory
                  onMenuChangeButtonPressed={this.onMenuChangeButtonPressed}
                  onEditCustomerButtonPressed={this.onEditCustomerButtonPressed}
                  userType={"admin"}
                />
              )}
              {activeIndexType === 1 && (
                <CareCustomerDetails
                  selectedCustomer={this.state.customerToEdit}
                />
              )}
              {activeIndexType === 2 && <TechnicianHistory />}
              {activeIndexType === 3 && <AdminSubscriptionCustomer userType={"admin"}/>}
              {activeIndexType === 4 && <VendorMenu />}
              {/* {activeIndexType === MenuIndex.OrganisationAccount && (
                <OrganisationAccounts />
              )}
              {activeIndexType === MenuIndex.SubscriptionsPlans && (
                <SubscriptionsPlans
                // adminToken={this.props.adminToken.adminToken}
                />
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
