import { Button, CircularProgress, TextField } from "@mui/material";
import React, { Component } from "react";
import "./createNewTechnicianModel.scss";
import { createTechnicianApi, updateTechnicianApi } from "../../Api";
import Swal from "sweetalert2";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


export default class CreateNewTechnicianModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registeredVehicles: this.props.selectedTechnicianToEdit?.registeredVehicles?this.props.selectedTechnicianToEdit.registeredVehicles:"",
      loading: false,
      name: this.props.selectedTechnicianToEdit.name
        ? this.props.selectedTechnicianToEdit.name
        : "",
      jobId: this.props.selectedTechnicianToEdit.jobId
        ? this.props.selectedTechnicianToEdit.jobId
        : "",
      phoneNo: this.props.selectedTechnicianToEdit.phoneNo
        ? this.props.selectedTechnicianToEdit.phoneNo
        : "",
    };
  }
   handleChange = (event) => {
   this.setState({registeredVehicles:event}) 
   console.log(event)
  };
  onSaveOrEditButtonPressed = async (btnType) => {
    this.setState({ loading: true });
    if (btnType === "create") {
      if (this.state.name.length > 0) {
        const res = await createTechnicianApi(
          this.state.name,
          this.state.phoneNo,
          this.state.jobId,
          this.state.registeredVehicles
        );
        if (res.isSuccess) {
          this.setState({ loading: false });
          Swal.fire({
            icon: "success",
            title: "Technician is created successfully.",
            showConfirmButton: true,
          });
          this.props.onCreateNewTechnicianCloseButtonPressed();
        } else {
          this.setState({ loading: false });
          Swal.fire({
            icon: "error",
            title: "Someting went wrong please try again after some time.",
            showConfirmButton: true,
          });
        }
      } else {
        this.setState({ loading: false });
        Swal.fire({
          icon: "warning",
          title: "All fields are mandatory.",
          showConfirmButton: true,
        });
      }
    } else {
      if (this.state.name.length > 0) {
        const res = await updateTechnicianApi(
          this.props.selectedTechnicianToEdit._id,
          this.state.name,
          this.state.phoneNo,
          this.state.jobId,
          this.state.registeredVehicles
        );
        if (res.isSuccess) {
          this.setState({ loading: false });
          Swal.fire({
            icon: "success",
            title: "Technician is updated successfully.",
            showConfirmButton: true,
          });
          this.props.onCreateNewTechnicianCloseButtonPressed();
        } else {
          this.setState({ loading: false });
          Swal.fire({
            icon: "error",
            title: "Someting went wrong please try again after some time.",
            showConfirmButton: true,
          });
        }
      } else {
        this.setState({ loading: false });
        Swal.fire({
          icon: "warning",
          title: "All fields are mandatory.",
          showConfirmButton: true,
        });
      }
    }
  };
  render() {
    return (
      <div className="create-technician-model">
        <div className="create-technician-model-container">
          <h2 style={{ marginBottom: "20px" }}>
            {this.props.typeOfModel === "create"
              ? "Create New Technician"
              : "Edit Technician"}
          </h2>
          <p className="create-technician-model-textField-lable">
            Technician Name
          </p>
          <TextField
            className="create-technician-model-textField"
            placeholder="Technican Name"
            defaultValue={this.props.selectedTechnicianToEdit.name}
            onChange={(e) => {
              this.setState({ name: e.target.value });
            }}
          />
          <p className="create-technician-model-textField-lable">
            Technician Mobile Number
          </p>
          <TextField
            className="create-technician-model-textField"
            placeholder="Technican Mobile Number"
            defaultValue={this.props.selectedTechnicianToEdit.phoneNo}
            onChange={(e) => {
              this.setState({ phoneNo: e.target.value });
            }}
          />
          <p className="create-technician-model-textField-lable">
            Technician Job Id
          </p>
          <TextField
            className="create-technician-model-textField"
            placeholder="Technican Job Id"
            defaultValue={this.props.selectedTechnicianToEdit.jobId}
            onChange={(e) => {
              this.setState({ jobId: e.target.value });
            }}
          />
           <Box sx={{ minWidth: "100%",marginTop:"15px" }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Vehicle's</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.registeredVehicles}
          label="Vehicle"
          defaultValue={this.state.registeredVehicles}
        >
          {this.props.registeredVehicles.map((vehicel)=>{return <MenuItem value={vehicel.deviceUniqueId} onClick={()=>{this.handleChange(vehicel.deviceUniqueId)}}>{vehicel.name}</MenuItem>})}
        </Select>
      </FormControl>
    </Box>
          <div className="create-technician-model-btn">
            <Button
              className="create-technician-model-closeBtn"
              variant="outlined"
              onClick={this.props.onCreateNewTechnicianCloseButtonPressed}
            >
              Close
            </Button>
            <Button
              className="create-technician-model-saveBtn"
              variant="contained"
              onClick={() => {
                this.onSaveOrEditButtonPressed(this.props.typeOfModel);
              }}
            >
              {this.state.loading ? (
                <CircularProgress
                  sx={{ height: "25px", width: "25px", color: "#fff" }}
                />
              ) : this.props.typeOfModel === "create" ? (
                "Save"
              ) : (
                "Edit"
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
