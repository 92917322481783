import React from "react";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import "./textField.scss";

const TextFieldWrapper = ({
  error,
  errorMsg,
  label,
  defaultValue,
  type,
  iconName,
  onChange,
  placeholder,
}) => {
  return (
    <div className="text-field">
      <div className="input-label">{label}</div>
      <TextField
        sx={{
          width: "100%",
        }}
        error={error}
        // id="outlined-error-helper-text"
        defaultValue={defaultValue ? defaultValue : null}
        placeholder={placeholder}
        helperText={error ? errorMsg : ""}
        type={type}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{iconName}</InputAdornment>
          ),
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default TextFieldWrapper;
