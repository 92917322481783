import React, { Component } from "react";
import "./adminSubscriptionCustomer.scss";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButtonGroup,
} from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import SearchIcon from "@mui/icons-material/Search";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { deleteCustomerApi, getCustomerDataApi } from "../../Api";
import AdminCustomerFullDetails from "../admin-cutomer-fdetails/AdminCustomerFullDetails";
import Swal from "sweetalert2";
import AdminSubscriptionCustomerTableRow from "./AdminSubscriptionCustomerTableRow";
import { getSubsCustomerDataApi } from "../../Api";
import AdminCustomerSubsFullDetails from "../admin-cutomer-fdetails/AdminCustomerSubsFullDetails";

const ToggleButton = styled(MuiToggleButton)(({}) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    // color: "white",
    backgroundColor: "#gray",
    fontWeight: "500",
    borderBottom: "3px solid #000",
    color: "#000",
  },
}));
export default class AdminSubscriptionCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditPlanScreens: false,
      showCreatePlanScreens: false,
      showStripScreens: false,
      filterSearchValue: "",
      filterData: [],
      loading: true,
      error: false,
      showViewTables: false,
      selectedCustomerToShow: "",
    };
  }
  componentDidMount() {
    this.getCustomerData();
  }
  getCustomerData = async () => {
    this.setState({ loading: true });
    const customer = await getSubsCustomerDataApi();
    console.log(customer);
    if (customer.isSuccess) {
      this.setState({ loading: false, filterData:customer.data });
    } else {
      this.setState({ loading: false, error: true });
    }
  };
  tableCellButtonPressed = (customer) => {
    this.setState({
      showViewTables: !this.state.showViewTables,
      selectedCustomerToShow: customer,
    });
  };
  onViewModelCloseButtonPressed = () => {
    this.setState({
      showViewTables: !this.state.showViewTables,
    });
  };
  onDeleteCustomerButtonPressed = async (id) => {
    this.setState({ loading: true });
    console.log(id);
    const response = await deleteCustomerApi(id);
    console.log(response);
    if (response.data.isSuccess) {
      Swal.fire({
        icon: "success",
        title: "This customer is deleted successfully form the database.",
        showConfirmButton: true,
      });
      this.setState({ loading: false });
      this.getCustomerData();
    } else {
      this.setState({ loading: false });
      Swal.fire({
        icon: "error",
        title: "something went wrong please try again later.",
        showConfirmButton: true,
      });
    }
  };
  render() {
    if (this.state.showEditPlanScreens) {
      return "hello";
      //   <EditPlan
      //     selectedSubscriptionForEdit={this.state.selectedSubscriptionForEdit}
      //     onPlanCreated={this.onPlanCreated}
      //   />
    } else if (this.state.showCreatePlanScreens) {
      return "<CreatePlan onPlanCreated={this.onPlanCreated} />";
    } else {
      return (
        <div className="subscriptions-plans">
          {this.state.showViewTables && (
            <AdminCustomerSubsFullDetails
              customer={this.state.selectedCustomerToShow}
              onCloseButtonPressed={this.onViewModelCloseButtonPressed}
            />
          )}
          <div className="subscriptions-plans-container">
            <div className="subscriptions-plans-header">
              <div className="subscriptions-plans-toggle-button-container">
                <Box m={1}>
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={this.state.showStripScreens ? 2 : 1}
                    //   onChange={this.onScreenSwipeButtonPressed}
                  >
                    <ToggleButton
                      value={1}
                      style={{ textTransform: "none", fontSize: "17px" }}
                    >
                      Customer History
                    </ToggleButton>

                    <ToggleButton
                      value={2}
                      style={{ textTransform: "none", fontSize: "17px" }}
                    >
                      Customer
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </div>

              <Button
                variant="outlined"
                className="subscriptions-plans-header-button"
                onClick={() => {
                  this.props.userType === "admin"
                    ? this.props.onMenuChangeButtonPressed(1)
                    : this.props.onMenuChangeButtonPressed("CutomerDetails");
                }}
                sx={{ textTransform: "none", fontSize: "17px" }}
                style={
                  this.state.showStripScreens
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                Create New Customer
              </Button>
            </div>
            {this.state.showStripScreens ? (
              "anoterh screen"
            ) : (
              <>
                <div className="subscriptions-plans-search-bar">
                  <div className="subscriptions-plans-search-area-container">
                    <div className="subscriptions-plans-search-search">
                      <SearchIcon className="search-icons" />
                      <TextField
                        fullWidth
                        id="fullWidth"
                        placeholder="Search cutomer by name."
                        className="subscriptions-plans-search-input"
                        onChange={(e) => {
                          this.setState({ filterSearchValue: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    className="subscriptions-plans-search-button"
                    //   onClick={this.onTableSearchButtonPressed}
                    sx={{ textTransform: "none", fontSize: "17px" }}
                  >
                    Search
                  </Button>
                </div>
                <div className="subscriptions-plans-table">
                  <TableContainer>
                    {" "}
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "600" }}
                          >
                            #
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "600" }}
                          >
                            Policy Number
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "600" }}
                          >
                            Customer Name
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "600" }}
                          >
                            Customer Phone No
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "600" }}
                          >
                            Vehicle Reg.
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "600" }}
                          >
                            Address 1
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "600" }}
                          >
                            Plan Price
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "600" }}
                          >
                            Plan Name
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "600" }}
                          >
                           Service To Give
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "600" }}
                          >
                            Buy date
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: "600" }}
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.loading ? (
                          <LinearProgress
                            sx={{
                              position: "absolute",
                              width: "100%!important",
                            }}
                            color="inherit"
                          />
                        ) : !this.state.loading &&
                          this.state.filterData.length > 0 ? (
                          this.state.filterData.map((customer, index) => (
                            <AdminSubscriptionCustomerTableRow
                              customer={customer}
                              index={index}
                              tableCellButtonPressed={
                                this.tableCellButtonPressed
                              }
                              onEditCustomerButtonPressed={
                                this.props.onEditCustomerButtonPressed
                              }
                              onDeleteCustomerButtonPressed={
                                this.onDeleteCustomerButtonPressed
                              }
                              loading={this.state.loading}
                              userType={this.props.userType}
                            />
                          ))
                        ) : (
                          <div
                            style={{
                              position: "absolute",
                              width: "100%",
                              textAlign: "center",
                              marginTop: "40px",
                            }}
                          >
                            <h1>No data found</h1>
                          </div>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* {this.state.filterData.length > 0 && (
                    <TablePaginationContainer
                      setSubscriptionTablePagination={() => {}}
                      pageSizee={0}
                      pageNo={0}
                      historyDetails={() => {}}
                    />
                  )} */}
                </div>
              </>
            )}
          </div>
        </div>
      );
    }
  }
}
