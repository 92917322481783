import React, { Component } from "react";
import "./customerCareDashboard.scss";
import GroupsIcon from "@mui/icons-material/Groups";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Menu from "../../small-componants/menu/Menu";
import Header from "../../componants/header/Header";
import Logo from "../../small-componants/logo/Logo";
import CareCustomerDetails from "../care-customer-details/CareCustomerDetails";
import CustomerHistory from "../customer-history/CustomerHistory";

export default class CustomerCareDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndexType: "CutomerDetails",
      customerToEdit: "",
    };
  }
  onMenuChangeButtonPressed = (val) => {
    this.setState({ activeIndexType: val, customerToEdit: {} });
    console.log(val);
  };
  onEditCustomerButtonPressed = (customer) => {
    this.setState({
      customerToEdit: customer,
      activeIndexType: "CutomerDetails",
    });
  };
  render() {
    const { activeIndexType } = this.state;
    return (
      <div className="dashbord">
        <div className="dashbord-container">
          <div className="dashbord-menu-container">
            <div className="dashbord-logo">
              <Logo fDirection="row" />
            </div>
            <div className="dashbord-menu-wrap">
              <Menu
                title="Customer Form"
                onClick={() => {
                  this.onMenuChangeButtonPressed("CutomerDetails");
                }}
                Icon={<PeopleAltIcon />}
                isButtonSelected={
                  activeIndexType === "CutomerDetails" ? true : false
                }
              />
              <Menu
                title="Customer History"
                onClick={() => {
                  this.onMenuChangeButtonPressed("customerHistory");
                }}
                Icon={<CurrencyExchangeIcon />}
                isButtonSelected={
                  activeIndexType === "customerHistory" ? true : false
                }
              />
            </div>
            {/* <div className="dashbord-menu-logoutButton"> </div> */}
          </div>
          <div className="dashbord-active-index">
            <div className="dashbord-active-index-header">
              <Header
                onChangePasswordButtonPressed={
                  // this.onChangePasswordButtonPressed
                  undefined
                }
              />
            </div>
            <div className="dashbord-active-index-container">
              {/* {this.state.showChangePasswordScreen && (
                <ChangePassword
                  adminToken={this.props.adminToken.adminToken}
                  onChangePasswordCancelButtonPressed={
                    this.onChangePasswordCancelButtonPressed
                  }
                />
              )} */}
              {activeIndexType === "CutomerDetails" && (
                <CareCustomerDetails
                  selectedCustomer={this.state.customerToEdit}
                />
              )}
              {activeIndexType === "customerHistory" && (
                <CustomerHistory
                  userType={"care"}
                  onMenuChangeButtonPressed={this.onMenuChangeButtonPressed}
                  onEditCustomerButtonPressed={this.onEditCustomerButtonPressed}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
