import {
    CircularProgress,
    IconButton,
    TableCell,
    TableRow,
  } from "@mui/material";
  import React, { Component } from "react";
  import { BiPencil } from "react-icons/bi";
  import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
  import Swal from "sweetalert2";
  
  import VisibilityIcon from "@mui/icons-material/Visibility";
  // import getSymbolFromCurrency from "currency-symbol-map";
  
  export default class AdminSubscriptionCustomerTableRow extends Component {
    constructor(props) {
      super(props);
      this.state = {
        laoding: false,
        error: false,
        priceLoading: false,
        showViewTables: false,
      };
    }
  
    //   onDeleteSubscriptionButtonPressed = async (id: string) => {
    //     Swal.fire({
    //       title: "Are you sure?",
    //       text: "You won't be able to revert this!",
    //       icon: "warning",
    //       showCancelButton: true,
    //       confirmButtonColor: "#3085d6",
    //       cancelButtonColor: "#d33",
    //       confirmButtonText: "Yes, delete it!",
    //     }).then(async (result) => {
    //       if (result.isConfirmed) {
    //         this.setState({ laoding: true, error: false });
    //         const token = this.props.adminToken;
    //         const response = await deleteSubscriptionApi(token, id);
    //         if (response.isSuccess) {
    //           this.props.getAllSubscription(0, 6);
    //           console.log(response);
    //           this.setState({ laoding: false });
    //         } else {
    //           Swal.fire({
    //             icon: "error",
    //             title: "something went wrong please try again later.",
    //             showConfirmButton: false,
    //           });
    //           this.setState({ laoding: false, error: false });
    //         }
    //       }
    //     });
    //   };
    render() {
      const { customer, index } = this.props;
      return (
        <>
          <TableRow key={index} style={{ cursor: "pointer" }}>
            <TableCell component="th" scope="row" align="center">
              {index + 1}
            </TableCell>
            <TableCell align="center">{customer.certificteNumber}</TableCell>
            <TableCell align="center">{customer.firstName}{" "}{customer.lastName}</TableCell>
            <TableCell align="center" sx={{ position: "relative" }}>
              {customer.phoneNo ? customer.phoneNo : "-"}
            </TableCell>
            <TableCell align="center" sx={{ position: "relative" }}>
              {customer.vehicleNumber ? customer.vehicleNumber : "-"}
            </TableCell>
            <TableCell align="center" sx={{ position: "relative" }}>
              {customer.addressCity ? customer.addressCity : "-"}
            </TableCell>
            <TableCell align="center" sx={{ position: "relative" }}>
              {customer.subscriptionPrice ? customer.subscriptionPrice : "-"}
            </TableCell>
            <TableCell align="center" sx={{ position: "relative" }}>
              {customer.subscriptionPlan ? customer.subscriptionPlan : "-"}
            </TableCell>
            <TableCell align="center" sx={{ position: "relative" }}>
              {customer.serviceProvided ? customer.serviceProvided : "-"}
            </TableCell>
            <TableCell align="center" sx={{ position: "relative" }}>
              {customer.createdAt ? customer.createdAt.slice(0,10) : "-"}
            </TableCell>
            <TableCell align="center" sx={{ position: "relative" }}>
              <IconButton
                onClick={() => {
                  this.props.onEditCustomerButtonPressed(customer);
                }}
              >
                <BiPencil />
              </IconButton>
              <IconButton
                onClick={() => {
                  this.props.tableCellButtonPressed(customer);
                }}
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                sx={
                  this.props.userType === "admin"
                    ? { display: "block", color: "red" }
                    : { display: "none", color: "red" }
                }
                onClick={() => {
                  this.props.onDeleteCustomerButtonPressed(customer._id);
                }}
              >
                {this.state.laoding ? (
                  <CircularProgress
                    sx={{
                      height: "23px!important",
                      width: "23px!important",
                      color: "#000",
                    }}
                  />
                ) : (
                  <DeleteOutlineIcon />
                )}
              </IconButton>
            </TableCell>
          </TableRow>
        </>
      );
    }
  }
  