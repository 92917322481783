export const caseStatus=[
    {key:"Pending",value:"Pending"},
    {key:"Completed",value:"Completed"},
    {key:"Cancel",value:"Cancel"},
    {key:"InProcess",value:"In process"},
    {key:"Others",value:"Others"},
]
export const vehicleServiceType=[
    {key:"RSR",value:"RSR"},
    {key:"2W_FBT",value:"2W_FBT"},
    {key:"4W_FBT",value:"4W_FBT"},
    {key:"Underlift",value:"Underlift"},
]