import { Avatar, Button, CircularProgress, IconButton } from "@mui/material";
import React, { Component } from "react";
import Logo from "../../small-componants/logo/Logo";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import { FiSettings } from "react-icons/fi";
import { HiOutlineClipboardList } from "react-icons/hi";
import KeyIcon from "@mui/icons-material/Key";
import "./header.scss";
import {
  getDataFromLocal,
  logOutUser,
} from "../../utils/globalFunctions/LogOut";

export class HeaderWrapper extends Component {
  constructor(props) {
    super(props);
    this.menuRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      showAvatarMenu: false,
      adminDetails: "",
      loading: false,
    };
  }
  componentDidMount() {
    const adminData = getDataFromLocal();
    this.setState({ adminDetails: adminData.data });
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  onMenuButtonPress = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };
  onLogOutButtonPressed = async () => {
    this.setState({ loading: true });
    logOutUser();
  };
  onAvatarButtonPressed = () => {
    this.setState({ showAvatarMenu: !this.state.showAvatarMenu });
  };
  onChangePasswordButtonPressed = () => {
    this.props.onChangePasswordButtonPressed();
    this.onAvatarButtonPressed();
  };
  handleClickOutside = (event) => {
    if (!this.menuRef.current.contains(event.target)) {
      this.setState({ showAvatarMenu: false });
    }
  };
  render() {
    const { isMenuOpen } = this.state;
    return (
      <div className="header">
        <div className="mobile-menu-container">
          {/* <MobileMenu isMenuOpen={isMenuOpen} /> */}
        </div>
        <div className="header-container">
          <div className="logo" style={{ visibility: "hidden" }}>
            <Logo fDirection="none" />
          </div>
          <div className="menu-button">
            <IconButton
              onClick={this.onMenuButtonPress}
              sx={{ textTransform: "none" }}
            >
              {isMenuOpen ? (
                <ClearIcon
                  style={{ height: "35px", width: "35px", color: "#fff" }}
                />
              ) : (
                <MenuIcon
                  style={{ height: "35px", width: "35px", color: "#000" }}
                />
              )}
            </IconButton>
          </div>
          <div className="menu-container">
            <div className="user-details" onClick={this.onAvatarButtonPressed}>
              <div className="user-avtar-container">
                <AccountCircleIcon className="user-avtar" />
              </div>
              <div className="user-name">
                <h2>{this.state.adminDetails.name}</h2>
              </div>
            </div>
            <div
              className="admin-avatar-menu"
              ref={this.menuRef}
              style={
                this.state.showAvatarMenu
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <ul className="admin-avatar-menu-ul">
                <li className="admin-avatar-menu-li">
                  <Button
                    variant="outlined"
                    startIcon={<AccountCircleIcon />}
                    className="admin-avatar-menu-button"
                    sx={{ textTransform: "none" }}
                  >
                    {this.state.adminDetails.email}
                    {/* {this.props.adminDetails.admin.adminDetail.username} */}
                  </Button>
                </li>
                <li className="admin-avatar-menu-li">
                  <Button
                    variant="outlined"
                    startIcon={<KeyIcon />}
                    className="admin-avatar-menu-button"
                    onClick={this.onChangePasswordButtonPressed}
                    sx={{ textTransform: "none" }}
                  >
                    Change Password
                  </Button>
                </li>
                <li className="admin-avatar-menu-li">
                  <Button
                    variant="outlined"
                    startIcon={<LogoutIcon />}
                    className="admin-avatar-menu-button"
                    onClick={this.onLogOutButtonPressed}
                    sx={{ textTransform: "none" }}
                  >
                    {this.state.loading ? (
                      <CircularProgress
                        sx={{
                          height: "23px!important",
                          width: "23px!important",
                          color: "#000",
                        }}
                      />
                    ) : (
                      "Logout"
                    )}
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Header = ({ onChangePasswordButtonPressed }) => {
  return (
    <HeaderWrapper
      onChangePasswordButtonPressed={onChangePasswordButtonPressed}
    />
  );
};
export default Header;
