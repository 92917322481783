import React, { Component } from "react";
import {
  Box,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { deleteDriverApi, getDriverDataApi } from "../../Api";
import Swal from "sweetalert2";
import SearchIcon from "@mui/icons-material/Search";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./technicaianHistory.scss";
import CreateNewTechnicianModel from "../../small-componants/create-new-technician/CreateNewTechnicianModel";
import TechnicianHistoryTableRow from "./TechnicianHistoryTableRow";
import axios from "axios";

export default class TechnicianHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      filterData: [],
      screenType: "history",
      showCreateTechnicianScreen: false,
      typeOfCreateTechnicianModel: "create",
      selectedTechnicianToEdit: {},
      deleteLoading: false,
      registeredVehicles:[],
    };
  }
  componentDidMount() {
    this.getTechnicainDetails();
  }
  getTechnicainDetails = async () => {
    this.setState({ loading: true });
    const response = await getDriverDataApi();
    console.log("driver response form technicaiton history", response);
    if (response.isSuccess) {
      this.setState({ filterData: response.data.data });
      await this.getAllRegisteredVehcleApi();
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
      Swal.fire({
        icon: "error",
        title: "something went wrong please try again later.",
        showConfirmButton: true,
      });
    }
  };
  onCreateNewTechnicianButtonPressed = (typeOfModel, selectedTechnician) => {
    this.setState({
      typeOfCreateTechnicianModel: typeOfModel,
      showCreateTechnicianScreen: true,
      selectedTechnicianToEdit: selectedTechnician,
    });
  };
  onCreateNewTechnicianCloseButtonPressed = () => {
    this.setState({
      showCreateTechnicianScreen: false,
      selectedTechnicianToEdit: {},
    });
    this.getTechnicainDetails();
  };
  onDeleteTechnicianButtonPressed = async (_id) => {
    this.setState({ deleteLoading: true });
    const res = await deleteDriverApi(_id);
    console.log(res);
    if (res.data.isSuccess) {
      this.setState({ deleteLoading: false });
      Swal.fire({
        icon: "success",
        title: "Technician is deleted successfully.",
        showConfirmButton: true,
      });
      this.getTechnicainDetails();
    } else {
      this.setState({ deleteLoading: false });
      Swal.fire({
        icon: "error",
        title: "Someting went wrong please try again after some time.",
        showConfirmButton: true,
      });
    }
  };
  getAllRegisteredVehcleApi=async()=>{
    try{
      const res =await fetch(process.env.REACT_APP_VEHICLE_API)
      const response= await res.json();
    if(response.successful){
      this.setState({registeredVehicles:response.object})
      console.log("-------------------------------------------------------")
    console.log(response.object);
    }
    else{
      Swal.fire({
        icon: "error",
        title: "Someting went wrong please try again after some time. Please refresh the page!",
        showConfirmButton: true,
      });
    }
    }
    catch(error){
      console.log(error)
      Swal.fire({
        icon: "error",
        title: "Someting went wrong please try again after some time. Please refresh the page!",
        showConfirmButton: true,
      });
    }
  }
  findVehicleForTableDriver=(vehicleId)=>{
    if(this.state.registeredVehicles){
      const vehcle=this.state.registeredVehicles.find((vehicel)=>vehicel.deviceUniqueId===vehicleId)
      return vehcle;
    }
    else{
      return null;
    }  
  }
  render() {
    return (
      <div className="subscriptions-plans">
        {this.state.showCreateTechnicianScreen && (
          <CreateNewTechnicianModel
            typeOfModel={this.state.typeOfCreateTechnicianModel}
            selectedTechnicianToEdit={this.state.selectedTechnicianToEdit}
            onCreateNewTechnicianCloseButtonPressed={
              this.onCreateNewTechnicianCloseButtonPressed
            }
            registeredVehicles={this.state.registeredVehicles}
          />
        )}
        <div className="subscriptions-plans-container">
          <div className="subscriptions-plans-header">
            <div className="subscriptions-plans-toggle-button-container">
              <Box m={1}>
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  value={this.state.showStripScreens ? 2 : 1}
                  //   onChange={this.onScreenSwipeButtonPressed}
                >
                  <ToggleButton
                    value={1}
                    style={{ textTransform: "none", fontSize: "17px" }}
                  >
                    Technician History
                  </ToggleButton>

                  <ToggleButton
                    value={2}
                    style={{ textTransform: "none", fontSize: "17px" }}
                  >
                    Technician Edit
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </div>

            <Button
              variant="outlined"
              className="subscriptions-plans-header-button"
              onClick={() => {
                this.onCreateNewTechnicianButtonPressed("create", {});
              }}
              sx={{ textTransform: "none", fontSize: "17px" }}
            >
              Create New Technician
            </Button>
          </div>
          {this.state.screenType === "create" && "anoterh screen"}{" "}
          {this.state.screenType === "history" && (
            <>
              <div className="subscriptions-plans-search-bar">
                <div className="subscriptions-plans-search-area-container">
                  <div className="subscriptions-plans-search-search">
                    <SearchIcon className="search-icons" />
                    <TextField
                      fullWidth
                      id="fullWidth"
                      placeholder="Search cutomer by name."
                      className="subscriptions-plans-search-input"
                      onChange={(e) => {
                        this.setState({ filterSearchValue: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <Button
                  variant="contained"
                  className="subscriptions-plans-search-button"
                  //   onClick={this.onTableSearchButtonPressed}
                  sx={{ textTransform: "none", fontSize: "17px" }}
                >
                  Search
                </Button>
              </div>
              <div className="subscriptions-plans-table">
                <TableContainer>
                  {" "}
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" style={{ fontWeight: "600" }}>
                          #
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: "600" }}>
                          Technician Name
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: "600" }}>
                          Technicaian Phone No
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: "600" }}>
                          Technician Job id
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: "600" }}>
                          Technician Vehicle
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: "600" }}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.loading ? (
                        <LinearProgress
                          sx={{
                            position: "absolute",
                            width: "100%!important",
                          }}
                          color="inherit"
                        />
                      ) : !this.state.loading &&
                        this.state.filterData.length > 0 ? (
                        this.state.filterData.map((driver, index) => (
                          <TechnicianHistoryTableRow
                            index={index}
                            customer={driver}
                            onDeleteTechnicianButtonPressed={
                              this.onDeleteTechnicianButtonPressed
                            }
                            onCreateNewTechnicianButtonPressed={
                              this.onCreateNewTechnicianButtonPressed
                            }
                            deleteLoading={this.state.deleteLoading}
                            registeredVehicles={this.findVehicleForTableDriver(driver.registeredVehicles)}
                          />
                        ))
                      ) : (
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            textAlign: "center",
                            marginTop: "40px",
                          }}
                        >
                          <h1>No data found</h1>
                        </div>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}




