import { Button, CircularProgress } from "@mui/material";
import React, { Component } from "react";
import "./simpleButton.scss";

export default class SimpleButton extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { buttonText, size, onClick, loading } = this.props;
    return (
      <div className="simple-button">
        <Button
          variant="contained"
          size="large"
          onClick={onClick}
          sx={{
            width: "100%",
            textAlign: "center",
            color: "#fff",
            backgroundColor: "red",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#000",
            },
          }}
        >
          {loading ? (
            <CircularProgress
              sx={{
                color: "#fff",
                height: "30px!important",
                width: "30px!important",
              }}
            />
          ) : (
            buttonText
          )}
        </Button>
      </div>
    );
  }
}
