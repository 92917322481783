import axios from "axios";

export const BASE_URL =
  process.env.NODE_ENV !== "production"
    ? "https://shiyaad-india-backend.vercel.app"
    : "http://localhost:8000";
export default axios.create({
  baseURL: BASE_URL,
  timeout: 6000,
});
