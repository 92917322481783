export const vehicleEventTypeJson=[
  {key:"OilPressureSensor",value: "Oil Pressure Sensor",},
  {key:"FlatBattery",value: "Flat Battery",},
  {key:"FlatTyre",value: "Flat Tyre",},
  {key:"EngineFanMotorMalfunction",value: "Engine Fan Motor Malfunction",},
  {key:"HornBlowingContinuously",value: "Horn Blowing Continuously",},
  {key:"Accident",value: "Accident",},
  {key:"FuelFilterBlockage",value: "Fuel Filter Blockage",},
  {key:"LooseBatteryTerminal",value: "Loose Battery Terminal",},
  {key:"Alternator",value: "Alternator",},
  {key:"IgnitionStartingProblem",value: "Ignition/Starting Problem",},
  {key:"IgnitionCoilBurntDamaged",value: "Ignition Coil Burnt/Damaged",},
  {key:"EngineOverheating",value: "Engine Overheating",},
  {key:"FanBeltBroken",value: "Fan Belt Broken",},
  {key:"SmokeEmissionFromBonnett",value: "Smoke Emission From Bonnett",},
  {key:"GearBoxMalfunction",value: "Gear Box Malfunction",},
  {key:"ABS",value: "ABS",},
  {key:"Accelerator",value: "Accelerator",},
  {key:"AutomaticTransmission",value: "Automatic Transmission",},
  {key:"CentralLockingFailure",value: "Central Locking Failure",},
  {key:"CoolantLeakage",value: "Coolant Leakage",},
  {key:"Fire",value: "Fire",},
  {key:"HeadlightsMalfunction",value: "Headlights Malfunction",},
  {key:"SRSMalfunctioning",value: "SRS Malfunctioning",},
  {key:"WaterIngress",value: "Water Ingress",},
  {key:"EngineBearingBroken",value: "Engine Bearing Broken",},
  {key:"BrakeFailure",value: "Brake Failure",},
  {key:"HighRPM",value: "High RPM",},
  {key:"BrokenAxle",value: "Broken Axle",},
  {key:"NeedBased",value: "Need Based",},
  {key:"AAA",value: "AAA",},
  {key:"GearLockProblem",value: "Gear Lock Problem",},
  {key:"SparkPlugDead",value: "Spark Plug Dead",},
  {key:"DischargedDeadBattery",value: "Discharged/Dead Battery",},
  {key:"TyreBurst",value: "Tyre Burst",},
  {key:"CondenserNotWorking",value: "Condenser Not Working",},
  {key:"BlownFuse",value: "Blown Fuse",},
  {key:"ElectricalAccessories",value: "Electrical Accessories",},
  {key:"DashboardComputer",value: "Dashboard Computer",},
  {key:"TyreBoltNotOpening",value: "Tyre Bolt Not Opening",},
  {key:"FuelLidNotOpening",value: "Fuel Lid Not Opening",},
  {key:"FuelPumpNotWorking",value: "Fuel Pump Not Working",},
  {key:"FuelPipeBrokenDamaged",value: "Fuel Pipe Broken/Damaged",},
  {key:"BrokenKeys",value: "Broken Keys",},
  {key:"InjectorMalfunction",value: "Injector Malfunction",},
  {key:"FuelLeakage",value: "Fuel Leakage",},
  {key:"DefectiveWiring",value: "Defective Wiring",},
  {key:"OilSumpPanLeakage",value: "Oil Sump/Pan Leakage",},
  {key:"OilPumpDamage",value: "Oil Pump Damage",},
  {key:"RadiatorLeakage",value: "Radiator Leakage",},
  {key:"ThermostatMalfunction",value: "Thermostat Malfunction",},
  {key:"CoolingSystemMalfunction",value: "Cooling System Malfunction",},
  {key:"BrakeFluidLeakage",value: "Brake Fluid Leakage",},
  {key:"BrakePedalMalfunction",value: "Brake Pedal Malfunction",},
  {key:"FrontDiscBrakeMalfunction",value: "Front Disc Brake Malfunction",},
  {key:"BrakePadShoeMalfunction",value: "Brake Pad/Shoe Malfunction",},
  {key:"HandBrakeJam",value: "Hand Brake Jam",},
  {key:"TWClutchCableBroken",value: "TW_Clutch Cable Broken",},
  {key:"ClutchPedalMalfunction",value: "Clutch Pedal Malfunction",},
  {key:"TyreHitAgainstKerbs",value: "Tyre Hit Against Kerbs",},
  {key:"PowerSteeringOilLeakageMalfunctioning",value: "Power Steering Oil Leakage/Malfunctioning",},
  {key:"DoorLockNotOpening",value: "Door Lock Not Opening",},
  {key:"BootLockOpen",value: "Boot Lock/Open",},
  {key:"DirectionalLightIndicators",value: "Directional Light/Indicators",},
  {key:"WindowGlassBrokenDamaged",value: "Window Glass Broken/Damaged",},
  {key:"MultipleFlatTyre",value: "Multiple Flat Tyre",},
  {key:"TyreVandalism",value: "Tyre Vandalism",},
  {key:"LostKeys",value: "Lost Keys",},
  {key:"TyreValveLeakage",value: "Tyre Valve Leakage",},
  {key:"OutOfFuel",value: "Out of Fuel",},
  {key:"IncorrectFuel",value: "Incorrect Fuel",},
  {key:"ContaminatedFuel",value: "Contaminated Fuel",},
  {key:"KeyLockedOut",value: "Key Locked Out",},
  {key:"KeysProblem",value: "Keys Problem",},
  {key:"SuspensionDamage",value: "Suspension Damage",},
  {key:"NoiseComingFromCar",value: "Noise Coming From Car",},
  {key:"TW_TyrePuncture",value: "TW_Tyre Puncture",},
  {key:"WheelJammed",value: "Wheel Jammed",},
  {key:"WiperNotWorking",value: "Wiper Not Working",},
  {key:"SmellFromTheCar",value: "Smell From The Car",},
  {key:"DischargedBattery",value: "Discharged Battery",},
  {key:"CarStoppedWhileDriving",value: "Car Stopped While Driving",},
  {key:"ACCIDENT_MINOR",value: "Accident Minor",},
  {key:"TW_BrakeFailure",value: "TW_Break Failure",},
  {key:"TW_KeyLost",value: "TW_Key Lost",},
  {key:"TW_OutOfFuel",value: "TW_Out of Fuel",},
  {key:"TW_Accelerator",value: "TW_Accelerator",},
  {key:"TW_Accident",value: "TW_Accident",},
  {key:"Battery_OutOfCharge",value: "Battery_Out_Of_Charge",},
  {key:"Petrol_OutOfFuel",value: "Petrol – Out of Fuel",},
  {key:"Diesel_OutOfFuel",value: "Diesel – Out of Fuel",},
  {key:"MMC_MRSA",value: "MMC-MRSA",},
  {key:"BatteryDischarged",value: "Battery Discharged",},
  {key:"AdBlueWarning",value: "AdBlue-Warning",},
  {key:"Repatriation",value: "Repatriation",},
  {key:"CylinderHead",value: "Cylinder Head",},
  {key:"WaterPump",value: "Water Pump",},
  {key:"IntakeAndExhaustManifolds",value: "Intake and Exhaust Manifolds",},
  {key:"Flywheel",value: "Flywheel",},
  {key:"WheelBearingBroken",value: "Wheel Bearing Broken",},
  {key:"ACCIDENT_MAJOR",value: "Accident Major",},
  {key:"TW_BatteryDischarged",value: "TW_Battery Discharged",},
  {key:"MMC_VRSA",value: "MMC-VRSA",},
  {key:"AccidentalBreakdown",value: "Accidental Breakdown",},
  {key:"BrakeOperations",value: "Brake Operations",},
  {key:"Transmission",value: "Transmission",},
  {key:"ConnectingRod",value: "Connecting Rod",},
  {key:"Valves",value: "Valves",},
  {key:"Camshaft",value: "Camshaft",},
  {key:"FuelPumpsAndInjectors",value: "Fuel Pumps and Injectors",},
  {key:"Radiator",value: "Radiator",},
  {key:"ECU",value: "ECU",},
  {key:"ABSSpeedSensors",value: "ABS/Speed Sensors",},
  {key:"ThrottlePositionSensor",value: "Throttle Position Sensor",},
  {key:"CrankAngleSensor",value: "Crank angle sensor",},
  {key:"WaterTemperatureSensor",value: "Water Temperature sensor",},
  {key:"AirMassFlowSensor",value: "Air Mass Flow sensor",},
  {key:"InletAirTemperatureSensor",value: "Inlet Air Temperature Sensor",},
  {key:"IdleSpeedControlSensor",value: "Idle Speed Control Sensor",},
  {key:"EngineSpeedSensor",value: "Engine speed sensor",},
  {key:"AbsolutePressureSensor",value: "Absolute pressure sensor",},
  {key:"Immobilizer",value: "Immobilizer",},
  {key:"BodyControlModule",value: "Body Control Module",},
  {key:"InternalGears",value: "Internal Gears",},
  {key:"AdjoiningShafts",value: "Adjoining Shafts",},
  {key:"SynchromeshRingsAndHubs",value: "Synchromesh rings and hubs",},
  {key:"GearboxHousing",value: "Gearbox Housing",},
  {key:"TransmissionFluidPump",value: "Transmission Fluid Pump",},
  {key:"SingleFlatTyre",value: "Single Flat Tyre",},
  {key:"StolenTyres",value: "Stolen Tyres",},
  {key:"ProductDemo",value: "Product Demo",},
  {key:"BrokenKey",value: "Broken Key",},
  {key:"KeyLocketOut",value: "Key Locket Out",},
  {key:"HornContinuouslyHonking",value: "Horn Continuously Honking",},
  {key:"IgnitionStartingIssues",value: "Ignition/Starting Issues",},
  {key:"PowerWindowMalfunction",value: "Power window malfunction",},
  {key:"PickupIssue",value: "Pickup Issue",},
  {key:"BootLockOpen",value: "Boot Lock/Open",},
  {key:"BrakePadShoeMalfunction",value: "Brake pad/shoe malfunction",},
  {key:"BrokenAxle",value: "Broken Axle",},
  {key:"CoolantLeakageLow",value: "Coolant Leakage/Low",},
  {key:"DoorLockNotOpening",value: "Door Lock Not Opening",},
  {key:"ElectricMotorMalfunction",value: "Electric Motor Malfunction",},
  {key:"HandBrakeJam",value: "Hand Brake Jam",},
  {key:"NoiseComingFromTheCar",value: "Noise Coming from the car",},
  {key:"ElectricPowerSteeringIssue",value: "Electric Power Steering Issue",},
  {key:"SmellFromTheCar",value: "Smell From The Car",},
  {key:"SmokeEmissionFromBonnet",value: "Smoke emission from bonnet",},
  {key:"SRSMalfunction",value: "SRS malfunction",},
  {key:"SuspensionDamage",value: "Suspension Damage",},
  {key:"WaterSubmerged",value: "Water Submerged",},
  {key:"WheelBearingBroken",value: "Wheel Bearing Broken",},
  {key:"WheelJam",value: "Wheel Jam",},
  {key:"WindowGlassBroken",value: "Window Glass Broken",},
  {key:"WiperNotWorking",value: "Wiper Not Working",},
  {key:"CarWashing",value: "Car Washing",},
  {key:"TWClutchCableBroken",value: "TW_CLUTCH CABLE BROKEN",},
  {key:"PowerSteeringFluidLow",value: "Power steering fluid low",},
  {key:"TheftCases",value: "Theft Cases",},
  {key:"DEFTankEmpty",value: "DEF Tank Empty",},
  {key:"ElectricalProblem",value: "Electrical Problem",},
  {key:"Emergency",value: "Emergency",},
  {key:"EmptyFuelTank",value: "Empty Fuel Tank",},
  {key:"EngineRelated",value: "Engine Related",},
  {key:"FlatTyreStepneyChange",value: "Flat Tyre/Stepney Change",},
  {key:"FuelOilCoolantLeakage",value: "Fuel/Oil/Coolant Leakage",},
  {key:"KeyLockedLost",value: "Key Locked/Lost",},
  {key:"PickUpIssue",value: "Pick Up Issue",},
  {key:"VehicleNotStarting",value: "Vehicle Not Starting",},
  {key:"Others",value: "Others",},
  {key:"ElectricalWiringIssue",value: "Electrical/Wiring issue",},
  {key:"OilPump",value: "Oil Pump",},
  {key:"ErrorOnCluster",value: "Error on cluster",},
  {key:"StartingIssue",value: "Starting issue",},
  {key:"Pistons",value: "Pistons",},
  {key:"EngineBlock",value: "Engine Block",},
  {key:"OutOfChargeMainBattery",value: "Out Of Charge Main Battery",},
  {key:"OutOfChargeAuxiliaryBattery",value: "Out Of Charge Auxiliary Battery",},
  {key:"BlownFuse",value: "Blown Fuse",},
  {key:"CentralLockingFailure",value: "Central Locking Failure",},
  {key:"DischargedDeadBattery",value: "Discharged/Dead Battery",},
  {key:"ElectronicIgnitionModule",value: "Electronic Ignition Module",},
  {key:"FuelPumpNotWorking",value: "Fuel Pump Not Working",},
  {key:"IgnitionCoilBurntDamaged",value: "Ignition Coil Burnt/Damaged",},
  {key:"LooseBatteryTerminal",value: "Loose Battery Terminal",},
  {key:"SparkPlugDead",value: "Spark Plug Dead",},
  {key:"DefectiveWiring",value: "Defective Wiring",},
  {key:"FuelFilterBlockage",value: "Fuel Filter Blockage",},
  {key:"InjectorMalfunction",value: "Injector Malfunction",},
  {key:"Alternator",value: "Alternator",},
  {key:"CoolingFanMotorMalfunction",value: "Cooling Fan Motor Malfunction",},
  {key:"EngineOverheating",value: "Engine Overheating",},
  {key:"WaterIngress",value: "Water Ingress",},
  {key:"ThermostatMalfunction",value: "Thermostat Malfunction",},
  {key:"OilSumpPanLeakage",value: "Oil Sump/Pan Leakage",},
  {key:"RadiatorLeakage",value: "Radiator Leakage",},
  {key:"CoolantLeakage",value: "Coolant Leakage",},
  {key:"EngineBearingBroken",value: "Engine Bearing Broken",},
  {key:"EngineFanMotorMalfunction",value: "Engine Fan Motor Malfunction",},
  {key:"EngineMountsBroken",value: "Engine Mounts Broken",},
  {key:"EngineOilLeakage",value: "Engine Oil Leakage",},
  {key:"ExhaustLeakage",value: "Exhaust Leakage",},
  {key:"FanBeltBroken",value: "Fan Belt Broken",},
  {key:"GearBoxMalfunction",value: "Gear Box Malfunction",},
  {key:"OilPressureSensor",value: "Oil Pressure Sensor",},
  {key:"SmokeEmissionFromExhaustPipe",value: "Smoke Emission From Exhaust Pipe",},
  {key:"Accelerator",value: "Accelerator",},
  {key:"AutomaticTransmission",value: "Automatic Transmission",},
  {key:"BrakeFailure",value: "Brake Failure",},
  {key:"ClutchPlateDamaged",value: "Clutch Plate Damaged",},
  {key:"Differential",value: "Differential",},
  {key:"DriveShaftBroken",value: "Drive Shaft Broken",},
  {key:"FlywheelDamaged",value: "Flywheel Damaged",},
  {key:"GearLinkageIssue",value: "Gear Linkage Issue",},
  {key:"ManualTransmission",value: "Manual Transmission",},
  {key:"PowerTransmissionFailure",value: "Power Transmission Failure",},
  {key:"ClutchCableIssue",value: "Clutch Cable Issue",},
  {key:"ClutchPedalMalfunction",value: "Clutch Pedal Malfunction",},
  {key:"ClutchPressurePlateIssue",value: "Clutch Pressure Plate Issue",},
  {key:"ClutchReleaseBearingIssue",value: "Clutch Release Bearing Issue",},
  {key:"ClutchSlippage",value: "Clutch Slippage",},
  {key:"FrontDiscBrakeMalfunction",value: "Front Disc Brake Malfunction",},
  {key:"RearDiscBrakeMalfunction",value: "Rear Disc Brake Malfunction",},
  {key:"BrakePedalMalfunction",value: "Brake Pedal Malfunction",},
  {key:"BrakePadWornOut",value: "Brake Pad Worn Out",},
  {key:"BrakeShoeWornOut",value: "Brake Shoe Worn Out",},
  {key:"HandBrakeNotHolding",value: "Hand Brake Not Holding",},
  {key:"ABS",value: "ABS",},
  {key:"BrakeFluidLeakage",value: "Brake Fluid Leakage",},
  {key:"PowerSteeringFluidLeakage",value: "Power Steering Fluid Leakage",},
  {key:"SteeringLinkageIssue",value: "Steering Linkage Issue",},
  {key:"SteeringRackIssue",value: "Steering Rack Issue",},
  {key:"SteeringWheelHardToTurn",value: "Steering Wheel Hard To Turn",},
  {key:"SuspensionBushesWornOut",value: "Suspension Bushes Worn Out",},
  {key:"SuspensionCoilSpringIssue",value: "Suspension Coil Spring Issue",},
  {key:"SuspensionShockAbsorberIssue",value: "Suspension Shock Absorber Issue",},
  {key:"TieRodEndIssue",value: "Tie Rod End Issue",},
  {key:"WheelAlignmentIssue",value: "Wheel Alignment Issue",},
  {key:"WheelBearingIssue",value: "Wheel Bearing Issue",},
  {key:"AirConditioningNotCooling",value: "Air Conditioning Not Cooling",},
  {key:"BlowerMotorMalfunction",value: "Blower Motor Malfunction",},
  {key:"CompressorMalfunction",value: "Compressor Malfunction",},
  {key:"EvaporatorCoilIssue",value: "Evaporator Coil Issue",},
  {key:"HeaterNotWorking",value: "Heater Not Working",},
  {key:"ACGasLeakage",value: "AC Gas Leakage",},
  {key:"ACControlPanelIssue",value: "AC Control Panel Issue",},
  {key:"CarElectricalsNotWorking",value: "Car Electricals Not Working",},
  {key:"FuseBoxIssue",value: "Fuse Box Issue",},
  {key:"HeadlightMalfunction",value: "Headlight Malfunction",},
  {key:"HornMalfunction",value: "Horn Malfunction",},
  {key:"IndicatorLightsNotWorking",value: "Indicator Lights Not Working",},
  {key:"InteriorLightMalfunction",value: "Interior Light Malfunction",},
  {key:"PowerWindowMalfunction",value: "Power Window Malfunction",},
  {key:"TaillightMalfunction",value: "Taillight Malfunction",},
  {key:"WiperBladesWornOut",value: "Wiper Blades Worn Out",},
  {key:"WiperMotorMalfunction",value: "Wiper Motor Malfunction",},
  {key:"WasherFluidEmpty",value: "Washer Fluid Empty",},
  {key:"ABSWarningLightOn",value: "ABS Warning Light On",},
  {key:"AirbagWarningLightOn",value: "Airbag Warning Light On",},
  {key:"CheckEngineLightOn",value: "Check Engine Light On",},
  {key:"CoolantTemperatureWarningLightOn",value: "Coolant Temperature Warning Light On",},
  {key:"LowFuelWarningLightOn",value: "Low Fuel Warning Light On",},
  {key:"OilPressureWarningLightOn",value: "Oil Pressure Warning Light On",},
  {key:"PowerSteeringWarningLightOn",value: "Power Steering Warning Light On",},
  {key:"TirePressureMonitoringSystem",value: "Tire Pressure Monitoring System",},
  {key:"TractionControlWarningLightOn",value: "Traction Control Warning Light On",},
  {key:"TransmissionWarningLightOn",value: "Transmission Warning Light On",},
  {key:"Others",value: "Others"},
]

export const vehicleEventTypeArray = [
    {key: "AAA",value: "AAA" },
    {key: "ABS",value: "ABS" },
    {key: "ACCIDENT_MAJOR",value: "Accident Major" },
    {key: "ACCIDENT_MINOR",value: "Accident Minor" },
    {key: "Accelerator",value: "Accelerator" },
    {key: "AdBlueWarning",value: "AdBlue-Warning" },
    {key: "AdjoiningShafts",value: "Adjoining Shafts" },
    {key: "AirConditioningNotCooling",value: "Air Conditioning Not Cooling" },
    {key: "AirMassFlowSensor",value: "Air Mass Flow sensor" },
    {key: "Alternator",value: "Alternator" },
    {key: "AutomaticTransmission",value: "Automatic Transmission" },
    {key: "BlownFuse",value: "Blown Fuse" },
    {key: "BootLockOpen",value: "Boot Lock/Open" },
    {key: "BrakeFailure",value: "Brake Failure" },
    {key: "BrakeFluidLeakage",value: "Brake Fluid Leakage" },
    {key: "BrakeOperations",value: "Brake Operations" },
    {key: "BrakePadShoeMalfunction",value: "Brake Pad/Shoe Malfunction" },
    {key: "BrakePedalMalfunction",value: "Brake Pedal Malfunction" },
    {key: "BrakeShoeWornOut",value: "Brake Shoe Worn Out" },
    {key: "BrokenAxle",value: "Broken Axle" },
    {key: "BrokenKey",value: "Broken Key" },
    {key: "BrokenKeys",value: "Broken Keys" },
    {key: "Camshaft",value: "Camshaft" },
    {key: "CarElectricalsNotWorking",value: "Car Electricals Not Working" },
    {key: "CarStoppedWhileDriving",value: "Car Stopped While Driving" },
    {key: "CarWashing",value: "Car Washing" },
    {key: "CentralLockingFailure",value: "Central Locking Failure" },
    {key: "ClutchCableIssue",value: "Clutch Cable Issue" },
    {key: "ClutchPadDamaged",value: "Clutch Pad Damaged" },
    {key: "ClutchPedalMalfunction",value: "Clutch Pedal Malfunction" },
    {key: "ClutchPlateDamaged",value: "Clutch Plate Damaged" },
    {key: "ClutchPressurePlateIssue",value: "Clutch Pressure Plate Issue" },
    {key: "ClutchReleaseBearingIssue",value: "Clutch Release Bearing Issue" },
    {key: "ClutchSlippage",value: "Clutch Slippage" },
    {key: "CompressorMalfunction",value: "Compressor Malfunction" },
    {key: "CondenserNotWorking",value: "Condenser Not Working" },
    {key: "ConnectingRod",value: "Connecting Rod" },
    {key: "ContaminatedFuel",value: "Contaminated Fuel" },
    {key: "CoolantLeakage",value: "Coolant Leakage" },
    {key: "CoolantLeakageLow",value: "Coolant Leakage/Low" },
    {key: "CoolingFanMotorMalfunction",value: "Cooling Fan Motor Malfunction" },
    {key: "CoolingSystemMalfunction",value: "Cooling System Malfunction" },
    {key: "CrankAngleSensor",value: "Crank angle sensor" },
    {key: "CylinderHead",value: "Cylinder Head" },
    {key: "DEFTankEmpty",value: "DEF Tank Empty" },
    {key: "DashboardComputer",value: "Dashboard Computer" },
    {key: "DefectiveWiring",value: "Defective Wiring" },
    {key: "DirectionalLightIndicators",value: "Directional Light/Indicators" },
    {key: "DischargedBattery",value: "Discharged Battery" },
    {key: "DischargedDeadBattery",value: "Discharged/Dead Battery" },
    {key: "DoorLockNotOpening",value: "Door Lock Not Opening" },
    {key: "DriveShaftBroken",value: "Drive Shaft Broken" },
    {key: "ECU",value: "ECU" },
    {key: "ElectricMotorMalfunction",value: "Electric Motor Malfunction" },
    {key: "EngineBelt",value: "Engine Belt" },
    {key: "EngineCoolingSystem",value: "Engine Cooling System" },
    {key: "EngineDamage",value: "Engine Damage" },
    {key: "EngineMisfire",value: "Engine Misfire" },
    {key: "EngineMount",value: "Engine Mount" },
    {key: "EngineNotStarting",value: "Engine Not Starting" },
    {key: "EngineOilLeakage",value: "Engine Oil Leakage" },
    {key: "EngineOilLevelLow",value: "Engine Oil Level Low" },
    {key: "EngineOverheating",value: "Engine Overheating" },
    {key: "EnginePerformanceIssue",value: "Engine Performance Issue" },
    {key: "EngineSeized",value: "Engine Seized" },
    {key: "EngineWarningLight",value: "Engine Warning Light" },
    {key: "ExhaustSystem",value: "Exhaust System" },
    {key: "FanBelt",value: "Fan Belt" },
    {key: "FaultyBrakeSystem",value: "Faulty Brake System" },
    {key: "FaultyFuelGauge",value: "Faulty Fuel Gauge" },
    {key: "FaultyOdometer",value: "Faulty Odometer" },
    {key: "FaultySpeedometer",value: "Faulty Speedometer" },
    {key: "FlatBattery",value: "Flat Battery" },
    {key: "FlatTyre",value: "Flat Tyre" },
    {key: "FlexDiscIssue",value: "Flex Disc Issue" },
    {key: "FuelDeliverySystem",value: "Fuel Delivery System" },
    {key: "FuelFilter",value: "Fuel Filter" },
    {key: "FuelGaugeMalfunction",value: "Fuel Gauge Malfunction" },
    {key: "FuelInjectionSystemMalfunction",value: "Fuel Injection System Malfunction" },
    {key: "FuelLevelLow",value: "Fuel Level Low" },
    {key: "FuelPumpMalfunction",value: "Fuel Pump Malfunction" },
    {key: "FuelTankCapIssue",value: "Fuel Tank Cap Issue" },
    {key: "FuelTankEmpty",value: "Fuel Tank Empty" },
    {key: "GearboxMount",value: "Gearbox Mount" },
    {key: "GearShiftIssue",value: "Gear Shift Issue" },
    {key: "GearSlipping",value: "Gear Slipping" },
    {key: "GloveBoxLockNotOpening",value: "Glove Box Lock Not Opening" },
    {key: "HarshRide",value: "Harsh Ride" },
    {key: "HeadGasketFailure",value: "Head Gasket Failure" },
    {key: "HeadlightMalfunction",value: "Headlight Malfunction" },
    {key: "HighEngineOilTemperature",value: "High Engine Oil Temperature" },
    {key: "HighExhaustEmissions",value: "High Exhaust Emissions" },
    {key: "HornMalfunction",value: "Horn Malfunction" },
    {key: "HydraulicSystemFailure",value: "Hydraulic System Failure" },
    {key: "IgnitionCoil",value: "Ignition Coil" },
    {key: "IgnitionControlModule",value: "Ignition Control Module" },
    {key: "IgnitionLock",value: "Ignition Lock" },
    {key: "IgnitionSwitchMalfunction",value: "Ignition Switch Malfunction" },
    {key: "IndicatorLightsMalfunction",value: "Indicator Lights Malfunction" },
    {key: "InjectorMalfunction",value: "Injector Malfunction" },
    {key: "InstrumentClusterMalfunction",value: "Instrument Cluster Malfunction" },
    {key: "IntakeManifold",value: "Intake Manifold" },
    {key: "IntermittentStartingProblem",value: "Intermittent Starting Problem" },
    {key: "KeyBatteryLow",value: "Key Battery Low" },
    {key: "KeyStuckInIgnition",value: "Key Stuck in Ignition" },
    {key: "KnockingNoise",value: "Knocking Noise" },
    {key: "LPGConversionKit",value: "LPG Conversion Kit" },
    {key: "LowBatteryVoltage",value: "Low Battery Voltage" },
    {key: "LowBrakeFluid",value: "Low Brake Fluid" },
    {key: "LowFuelEfficiency",value: "Low Fuel Efficiency" },
    {key: "LowFuelLevel",value: "Low Fuel Level" },
    {key: "LowOilPressure",value: "Low Oil Pressure" },
    {key: "LowTyrePressure",value: "Low Tyre Pressure" },
    {key: "MalfunctioningABS",value: "Malfunctioning ABS" },
    {key: "MalfunctioningECM",value: "Malfunctioning ECM" },
    {key: "MalfunctioningPCM",value: "Malfunctioning PCM" },
    {key: "MalfunctioningTCM",value: "Malfunctioning TCM" },
    {key: "MisalignedWheels",value: "Misaligned Wheels" },
    {key: "MisfireDetected",value: "Misfire Detected" },
    {key: "OilLeakage",value: "Oil Leakage" },
    {key: "OilLevelLow",value: "Oil Level Low" },
    {key: "OverheatingEngine",value: "Overheating Engine" },
    {key: "ParkingBrakeMalfunction",value: "Parking Brake Malfunction" },
    {key: "PowerSteeringMalfunction",value: "Power Steering Malfunction" },
    {key: "RadiatorLeakage",value: "Radiator Leakage" },
    {key: "RadiatorMalfunction",value: "Radiator Malfunction" },
    {key: "RearAxle",value: "Rear Axle" },
    {key: "RearViewMirrorIssue",value: "Rear View Mirror Issue" },
    {key: "SeatBeltMalfunction",value: "Seat Belt Malfunction" },
    {key: "ShockAbsorberMalfunction",value: "Shock Absorber Malfunction" },
    {key: "SparkPlugIssue",value: "Spark Plug Issue" },
    {key: "StarterMotorMalfunction",value: "Starter Motor Malfunction" },
    {key: "SteeringColumnLock",value: "Steering Column Lock" },
    {key: "SteeringLinkageIssue",value: "Steering Linkage Issue" },
    {key: "SteeringWheelLocked",value: "Steering Wheel Locked" },
    {key: "SuspensionSystem",value: "Suspension System" },
    {key: "TemperatureGaugeMalfunction",value: "Temperature Gauge Malfunction" },
    {key: "ThrottleBodyMalfunction",value: "Throttle Body Malfunction" },
    {key: "TimingBelt",value: "Timing Belt" },
    {key: "TireBlowout",value: "Tire Blowout" },
    {key: "TransmissionControlModule",value: "Transmission Control Module" },
    {key: "TransmissionFluidLeakage",value: "Transmission Fluid Leakage" },
    {key: "TransmissionIssue",value: "Transmission Issue" },
    {key: "TurbochargerMalfunction",value: "Turbocharger Malfunction" },
    {key: "UnderinflatedTyre",value: "Underinflated Tyre" },
    {key: "UniversalJointIssue",value: "Universal Joint Issue" },
    {key: "VacuumLeakage",value: "Vacuum Leakage" },
    {key: "ValveCoverGasketLeakage",value: "Valve Cover Gasket Leakage" },
    {key: "VehicleDent",value: "Vehicle Dent" },
    {key: "VehicleScratch",value: "Vehicle Scratch" },
    {key: "WasherFluidLow",value: "Washer Fluid Low" },
    {key: "WheelAlignmentIssue",value: "Wheel Alignment Issue" },
    {key: "WheelBearingIssue",value: "Wheel Bearing Issue" },
    {key: "WheelRimDamage",value: "Wheel Rim Damage" },
    {key: "WindowRegulatorMalfunction",value: "Window Regulator Malfunction" },
    {key: "WiperBladesIssue",value: "Wiper Blades Issue" },
    {key: "WornOutBrakePads",value: "Worn Out Brake Pads" },
    {key: "WornOutClutch",value: "Worn Out Clutch" },
    {key: "WornOutTyre",value: "Worn Out Tyre" },
    {key: "WrongFuelFilled",value: "Wrong Fuel Filled" },
  ];
  