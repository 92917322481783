import React from "react";
import { useParams } from "react-router-dom";
import failPayment from "./../../assets/failPayment.png";
import { Button } from "@mui/material";

const PaymentFailPage = () => {
  const { paymentId } = useParams();
  return (
    <div className="payment-success-page">
      <div className="payment-success-img-container">
        <img src={failPayment} className="payment-success-img" />
        <div className="payment-success-button-container">
          {" "}
          <Button
            className="payment-success-button"
            variant="contained"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Go Back To Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailPage;
