import React, { Component } from "react";
import DashboardDrawer from "../dashboardDrawer/DashboardDrawer";

export default class VendorDashboard extends Component {
  render() {
    return (
      <>
        <DashboardDrawer adminDetails={this.props.adminDetails} />
      </>
    );
  }
}
