import React, { Component } from "react";
import {
  CircularProgress,
  IconButton,
  TableRow,
  TableCell,
} from "@mui/material";
import { BiPencil } from "react-icons/bi";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default class TechnicianHistoryTableRow extends Component {
  constructor(props){
super(props);
this.state={
  vehcleName:"",
}
  }
  componentDidMount(){
    // console.log(this.props.customer.registeredVehicles)
    // if(this.props.customer.registeredVehicles){
    //   const vehcle=this.props.customer.registeredVehicles.find((vehicel)=>vehicel.deviceUniqueId===this.props.customer.registeredVehicles)
    //   this.setState({vehcleName:vehcle.name});
    //   console.log("===============",vehcle)
    // }
    // else{

    // }  
  }
  render() {
    const {
      index,
      customer,
      onCreateNewTechnicianButtonPressed,
      onDeleteTechnicianButtonPressed,
      deleteLoading,
    } = this.props;
    return (
      <>
        <TableRow key={index} style={{ cursor: "pointer" }}>
          <TableCell component="th" scope="row" align="center">
            {index + 1}
          </TableCell>
          <TableCell align="center">{customer.name}</TableCell>
          <TableCell align="center" sx={{ position: "relative" }}>
            {customer.phoneNo ? customer.phoneNo : "-"}
          </TableCell>
          <TableCell align="center" sx={{ position: "relative" }}>
            {customer.jobId ? customer.jobId : "--"}
          </TableCell>
          <TableCell align="center" sx={{ position: "relative" }}>
            {this.props.registeredVehicles ? this.props.registeredVehicles.name : "--"}
          </TableCell>
          <TableCell align="center" sx={{ position: "relative" }}>
            <IconButton
              onClick={() => {
                onCreateNewTechnicianButtonPressed("edit", customer);
              }}
            >
              <BiPencil />
            </IconButton>
            <IconButton
              sx={{ color: "red" }}
              onClick={() => {
                onDeleteTechnicianButtonPressed(customer._id);
              }}
            >
              {deleteLoading ? (
                <CircularProgress
                  sx={{
                    height: "23px!important",
                    width: "23px!important",
                    color: "#000",
                  }}
                />
              ) : (
                <DeleteOutlineIcon />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
      </>
    );
  }
}
