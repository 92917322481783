import React, { Component } from "react";
import "./adminCustomerFullDetails.scss";
import { Button, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

export default class AdminCustomerSubsFullDetails extends Component {
    timestampToDate=(timestamp)=>{
var date = new Date(timestamp);
var year = date.getFullYear();
var month = date.getMonth() + 1; // Months are zero-based, so add 1
var day = date.getDate();

return year + '-' + month + '-' + day;
    }
  render() {
    return (
      <div className="admin-customer-full-details">
        <div className="admin-customer-full-details-container">
          <div className="admin-customer-full-header">
            <h3>Customer Details</h3>
            <IconButton
              className="admin-customer-full-details-button"
              onClick={this.props.onCloseButtonPressed}
            >
              <Clear />
            </IconButton>
          </div>
          <div
            className="admin-customer-full-details-details"
            style={{ marginTop: "30px" }}
          >
            <span style={{ fontWeight: "600" }}>Customer Policy No:</span>{" "}
            {this.props.customer.certificteNumber}
          </div>
          <div
            className="admin-customer-full-details-details"
            style={{ marginTop: "30px" }}
          >
            <span style={{ fontWeight: "600" }}>Customer Name:</span>{" "}
            {this.props.customer.firstName}{" "}{this.props.customer.lastName}
          </div>
          <div
            className="admin-customer-full-details-details"
            style={{ marginTop: "30px" }}
          >
            <span style={{ fontWeight: "600" }}>customerPhone:</span>{" "}
            {this.props.customer.phoneNo}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>customerEmail:</span>{" "}
            {this.props.customer.email}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>address1:</span>{" "}
            {this.props.customer.address1}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>address2:</span>{" "}
            {this.props.customer.address2}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>addressState:</span>{" "}
            {this.props.customer.addressState}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>addressCity:</span>{" "}
            {this.props.customer.addressCity}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>vehicleModel:</span>{" "}
            {this.props.customer.vehicleModel}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>vehicleNumber:</span>{" "}
            {this.props.customer.vehicleNumber}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>vehicleManufacturer:</span>{" "}
            {this.props.customer.vehicleManufacturer}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>vehicleEngineNumber:</span>{" "}
            {this.props.customer.vehicleEngineNumber}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>vehicleChassisNumber:</span>{" "}
            {this.props.customer.vehicleChassisNumber}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>totalAmount:</span>{" "}
            {this.props.customer.totalAmount}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>
              subscriptionPlan:
            </span>{" "}
            {this.props.customer.subscriptionPlan}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>subscriptionPrice:</span>{" "}
            {this.props.customer.subscriptionPrice}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>certificteStartDate:</span>{" "}
            {this.props.customer.createdAt.slice(0,10)}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>certificteEndDate:</span>{" "}
            {this.timestampToDate(Number(this.props.customer.certificteEndDate))}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>No of Service(to give):</span>{" "}
            {this.props.customer.serviceProvided}
          </div>
          <div className="admin-customer-full-details-details">
            <span style={{ fontWeight: "600" }}>Payment Status:</span>{" "}
            {this.props.customer.isPaymentSuccess}
          </div>
        </div>
      </div>
    );
  }
}
