import React, { Component, lazy } from "react";
import "./loginPage.scss";
import TextFieldWithBothIcon from "../../small-componants/textField/TextFieldWithBothIcon";
import SimpleButton from "../../small-componants/simpleButton/SimpleButton";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import TextFieldWrapper from "../../small-componants/textField/TextFieldWrapper";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useHistory } from "react-router-dom";
import Dashboard from "../dashboard-screen/Dashboard";
import swal from "sweetalert";
import { Box } from "@mui/material";
import { ToggleButtonGroup } from "@mui/material";
import { ToggleButton } from "@mui/material";
import Swal from "sweetalert2";
import { adminLoginApi, validateAdminApi } from "../../Api";
import CurcularLoader from "../../componants/loader/CurcularLoader";
import Footer from "../../componants/footer-compo/Footer";
export class LoginPageWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailForLogin: "",
      passwordForLogin: "",
      showPassword: false,
      showError: false,
      loginButtonLoading: false,
      isValidUser: false,
      showDashboard: false,
      screenType: "admin",
    };
  }
  validateEmail = (email) => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (filter.test(email)) {
      return true;
    } else {
      return false;
    }
  };
  componentDidMount() {
    this.chekUserValidity();
  }
  onLoginButtonPress = async () => {
    this.setState({ loginButtonLoading: true });
    console.log(this.state.emailForLogin);
    if (this.validateEmail(this.state.emailForLogin)) {
      if (this.state.emailForLogin && this.state.passwordForLogin) {
        const response = await adminLoginApi(
          this.state.emailForLogin,
          this.state.passwordForLogin,
          this.state.screenType
        );
        console.log(response);
        if (response.isSuccess) {
          this.props.history.push("/dashboard");
          localStorage.setItem("adminsData", JSON.stringify(response.data));
          this.setState({ loginButtonLoading: false });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please enter valid details.",
          });
          this.setState({ loginButtonLoading: false, error: true });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please fill the all fields below.",
        });
        this.setState({ loginButtonLoading: false, error: true });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a valid email address.!",
      });
      this.setState({ loginButtonLoading: false, error: true });
    }
  };
  chekUserValidity = async () => {
    this.setState({ loginButtonLoading: true });
    const admin = JSON.parse(localStorage.getItem("adminsData"));
    console.log(admin);
    if (admin) {
      const reponse = await validateAdminApi(
        admin.data.email,
        admin.data.userType,
        admin.data._id
      );
      if (reponse.isSuccess) {
        this.props.history.push("/dashboard");
        this.setState({ loginButtonLoading: false, isValidUser: true });
      } else {
        this.setState({ loginButtonLoading: false });
      }
    } else {
      this.setState({ loginButtonLoading: false });
    }
  };
  onClickPasswordIcon = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  onForgotPasswordButtonPressed = () => {};
  onScreenSwipeButtonPressed = (value) => {
    this.setState({ screenType: value });
  };
  render() {
    const { isValidUser, showPassword, passwordForLogin, emailForLogin } =
      this.state;
    const baseClassName = "";
    if (isValidUser) {
      return <CurcularLoader />;
    } else {
      return (
        <>
          <div className="login">
            <div className="container">
              <div className={baseClassName + "-toggle-button-container"}>
                <Box m={1}>
                  <ToggleButtonGroup color="primary" exclusive>
                    <ToggleButton
                      value={1}
                      style={
                        this.state.screenType === "admin"
                          ? {
                              borderBottom: "3px solid black",
                              textTransform: "none",
                              fontSize: "17px",
                              color: "#000",
                              backgroundColor: "rgb(64 130 195 / 24%)",
                            }
                          : {
                              textTransform: "none",
                              fontSize: "17px",
                            }
                      }
                      onClick={() => {
                        this.onScreenSwipeButtonPressed("admin");
                      }}
                    >
                      Admin Login
                    </ToggleButton>
                    <ToggleButton
                      value={2}
                      style={
                        this.state.screenType === "care"
                          ? {
                              borderBottom: "3px solid black",
                              textTransform: "none",
                              fontSize: "17px",
                              color: "#000",
                              backgroundColor: "rgb(64 130 195 / 24%)",
                            }
                          : {
                              textTransform: "none",
                              fontSize: "17px",
                            }
                      }
                      onClick={() => {
                        this.onScreenSwipeButtonPressed("care");
                      }}
                    >
                      CustomerCare Login
                    </ToggleButton>
                    <ToggleButton
                      value={3}
                      style={
                        this.state.screenType === "vendor"
                          ? {
                              borderBottom: "3px solid black",
                              textTransform: "none",
                              fontSize: "17px",
                              color: "#000",
                              backgroundColor: "rgb(64 130 195 / 24%)",
                            }
                          : {
                              textTransform: "none",
                              fontSize: "17px",
                            }
                      }
                      onClick={() => {
                        this.onScreenSwipeButtonPressed("vendor");
                      }}
                    >
                      Vendor Login
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </div>
              <div className="logo">
                <div className="logo-svg">{/* <img src={LogoPng} /> */}</div>
                {/* <h4 className="logo-text">Owner Login</h4> */}
              </div>
              <div className="email-textField">
                <TextFieldWrapper
                  error={this.state.error}
                  errorMsg={"Please Enter the Correct Email Address"}
                  label={"Email"}
                  placeholder="Email Address"
                  type={"email"}
                  iconName={<MailOutlineIcon />}
                  onChange={(event) => {
                    this.setState({ emailForLogin: event.target.value });
                  }}
                />
              </div>
              <div className="password-textField">
                <TextFieldWithBothIcon
                  error={this.state.error}
                  errorMsg={"Please Enter the Correct Password"}
                  label={"Password"}
                  type={showPassword ? "text" : "password"}
                  firstIconName={
                    !showPassword ? (
                      <LockOutlinedIcon />
                    ) : (
                      <LockOpenOutlinedIcon />
                    )
                  }
                  onClickPasswordIcon={this.onClickPasswordIcon}
                  showPassword={showPassword}
                  onChange={(event) => {
                    this.setState({ passwordForLogin: event.target.value });
                  }}
                  placeholder="Password"
                />
              </div>
              <div
                className="forget-password"
                onClick={this.onForgotPasswordButtonPressed}
              >
                <span className="link">Forgot Password?</span>
              </div>
              <div className="login-button">
                <SimpleButton
                  buttonText="Log In"
                  size="medium"
                  onClick={this.onLoginButtonPress}
                  loading={this.state.loginButtonLoading}
                  // loading={true}
                />
              </div>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  }
}

const LoginPage = () => {
  const history = useHistory();
  return <LoginPageWrapper history={history} />;
};
export default LoginPage;
