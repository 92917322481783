import baseUrl from "./baseUrl";
import axios from "axios";
import qs from "qs";

const instance = axios.create({
  paramsSerializer: {
    encode: qs.parse,
    serialize: qs.stringify,
  },
});
export const adminLoginApi = async (userName, password, userType) => {
  console.log(userName, password);
  console.log(userType);
  try {
    const res = await baseUrl.post("/admin/adminLogin", {
      email: userName,
      password: password,
      userType: userType,
    });

    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const validateAdminApi = async (userName, userType, _id) => {
  console.log(userType);
  try {
    const res = await baseUrl.post("admin/adminValidate", {
      email: userName,
      userType: userType,
      _id: _id,
    });

    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const createTechnicianApi = async (name, phoneNo, jobId,registeredVehicles) => {
  try {
    const res = await baseUrl.post("driver/create", {
      name: name,
      phoneNo: phoneNo,
      status: true,
      jobId: jobId,
      registeredVehicles:registeredVehicles,
    });

    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const updateTechnicianApi = async (_id, name, phoneNo, jobId,registeredVehicles) => {
  try {
    const res = await baseUrl.post("driver/update", {
      _id: _id,
      name: name,
      phoneNo: phoneNo,
      status: true,
      jobId: jobId,
      registeredVehicles:registeredVehicles,
    });

    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getVisiterDataApi = async (pageSize, pageNo) => {
  console.log(pageNo, pageSize);
  try {
    const res = await baseUrl.get(
      `/visiter/visiterDetails/pageNo/${pageNo}/pageSize/${pageSize}`
    );
    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getSubsCustomerDataApi = async (pageSize, pageNo) => {
  console.log(pageNo, pageSize);
  try {
    const res = await baseUrl.get(
      `/customer/subs/getall`
    );
    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getVendorDataApi = async () => {
  try {
    const res = await baseUrl.get(
      `/vendor/get`
    );
    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const createVendorDataApi = async (vendorDetails) => {
  try {
    const res = await baseUrl.post(
      `/vendor/create`,vendorDetails
    );
    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const updateVendorDataApi = async (vendorDetails) => {
  try {
    const res = await baseUrl.post(
      `/vendor/update`,vendorDetails
    );
    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const deleteVendorDataApi = async (_id) => {
  try {
    const res = await baseUrl.post(
      `/vendor/delete`,{_id}
    );
    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getCustomerDataApi = async () => {
  try {
    const res = await baseUrl.get(`/customer/getCustomer/`);
    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getDriverDataApi = async () => {
  try {
    const res = await baseUrl.get(`/driver/getDriver/`);
    console.log(res);
    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

// export const sendMessageToCustomerApi = async (num, customerDetails) => {
//   const response = await fetch(
//     `http://135.181.75.92/http-jsonapi.php?username=shivaay001&password=shivaay001&senderid=SRANID&route=1&number=${num}&message=Dear ${customerDetails.customerName}%0A%0A
// Your case has been assigned to driver.%0A
// Please find the driver details below%0A
// Driver Name ${customerDetails.technicianName}%0A
// Driver's Contact No ${customerDetails.technicianPhone}%0A%0A
// If any query contact us with%0A
// Email Id : mohan@gmail.com | Contact No : 9012155581%0A%0A
// Regards%0A
// Shivaayrsaindia&templateid=1507166123674009393
// `
//   );
//   return response.json();
// };
export const sendMessageToDriverApi = async (num, customerDetails) => {
  const response = await fetch(
    `http://135.181.75.92/http-jsonapi.php?username=shivaay001&password=shivaay001&senderid=SRANID&route=1&number=${num}&message=A NEW RSA Case 001 has been assigned to you.
Vehicle No ${customerDetails.technicianRegNo}
Customer Name ${customerDetails.customerName}
Customer's Contact No ${customerDetails.customerPhone}
link link link ReachTime 10:AM
Click www.shivaayindia.com
Click for image upload
link link link
Regard
Shivaayrsaindia
&templateid=1507166131379033661
`
  );
  return response.json();
};

export const createCustomer = async (customerDetails) => {
  console.log(customerDetails);
  try {
    const res = await baseUrl.post(`/customer/create`, {
      caseId: customerDetails.caseId ? customerDetails.caseId : "",
      customerName: customerDetails.customerName
        ? customerDetails.customerName
        : "",
      customerPhone: customerDetails.customerPhone
        ? customerDetails.customerPhone
        : "",
      customerRegNo: customerDetails.customerRegNo
        ? customerDetails.customerRegNo
        : "",
      vehicleBrand: customerDetails.vehicleBrand
        ? customerDetails.vehicleBrand
        : "",
      cutomerLocation: customerDetails.cutomerLocation
        ? customerDetails.cutomerLocation
        : "",
      deleveryDate: customerDetails.deleveryDate
        ? customerDetails.deleveryDate
        : "",
      jobTime: customerDetails.jobTime ? customerDetails.jobTime : "",
      otherDetails: customerDetails.otherDetails
        ? customerDetails.otherDetails
        : "",
      reachingTime: customerDetails.reachingTime
        ? customerDetails.reachingTime
        : "",
      caseDate: customerDetails.caseDate ? customerDetails.caseDate : "",
      technicianName: customerDetails.technicianName
        ? customerDetails.technicianName
        : "",
      technicianJobNo: customerDetails.technicianJobNo
        ? customerDetails.technicianJobNo
        : "",
      technicianVehicleReadings: customerDetails.technicianVehicleReadings
        ? customerDetails.technicianVehicleReadings
        : "",
      technicianVehicleKM: customerDetails.technicianVehicleKM
        ? customerDetails.technicianVehicleKM
        : "",
      reportedFault: customerDetails.reportedFault
        ? customerDetails.reportedFault
        : "",
      technicianPhone: customerDetails.technicianPhone
        ? customerDetails.technicianPhone
        : "",
      caseStatus: customerDetails.caseStatus ? customerDetails.caseStatus : "",
      technicianLocation: customerDetails.technicianLocation
        ? customerDetails.technicianLocation
        : "",
    });
    if (res.data.isSuccess) {
      return res.data;
    } else {
      throw new Error("something went wrong");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const sendMessageToCustomerApi = async (customerDetails) => {
  console.log("called");
  if (
    customerDetails.customerName &&
    customerDetails.customerPhone &&
    customerDetails.technicianName &&
    customerDetails.technicianPhone
  ) {
    try {
      const response = await fetch(
        `https://www.fast2sms.com/dev/bulkV2?authorization=XEeNZ6oaQ7ywT8dj0KgPMR4pq5SifnBvDHGuI9lmOVLFCYbAszWclhOoZ9FPJ2BigCxesprdM04QKVzA&route=dlt&sender_id=SRANID&message=153754&variables_values=${
          customerDetails.customerName
        }%7C${customerDetails.technicianName}%7C${
          customerDetails.technicianPhone
        }%7C${"feedback@shivaayrsaindia.com"}%7C${"01144759901"}%7C&flash=0&numbers=${
          customerDetails.customerPhone
        }`
      );
      console.log(response);
      return response.json();
    } catch (error) {
      console.log(error);
      return false;
    }
  }
};
export const sendMessageToCustomerForLocationApi = async (customerName,customerPhone,link1,link2,link3) => {
  if (
    customerName &&
    customerPhone &&
    link1,
    link2,
    link3
  ) {
    try {
      const response = await axios.get(
        ` https://www.fast2sms.com/dev/bulkV2?authorization=XEeNZ6oaQ7ywT8dj0KgPMR4pq5SifnBvDHGuI9lmOVLFCYbAszWclhOoZ9FPJ2BigCxesprdM04QKVzA&route=dlt&sender_id=SRANID&message=153753&variables_values=${customerName}%7C${link1}%7C${link2}%7C${link3}%7C&flash=0&numbers=${customerPhone}`
      );
      console.log(response.data);
      if(response.data.return){
        return response.data;
      }
      else{
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
};

export const deleteCustomerApi = async (_id) => {
  try {
    const response = await baseUrl.post("/customer/removeCustomer", {
      _id: _id,
    });
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getCustomerByIdApi = async (_id) => {
  try {
    const response = await baseUrl.get(`/customer/getById/${_id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const deleteDriverApi = async (_id) => {
  try {
    const response = await baseUrl.post("driver/delete", {
      _id: _id,
    });
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const createOrderApi = async (amount, notes) => {
  try {
    const response = await baseUrl.post("payment/createOrder", {
      amount: amount,
      notes: notes,
    });
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const createCustomerWithSubsApi = async (customerDetails) => {
  try {
    const response = await baseUrl.post(
      "customer/subs/create",
      customerDetails
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getRazorKeyApi = async () => {
  try {
    const response = await baseUrl.get("payment/getKey");
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getSubscriptedUserApi = async (_id) => {
  try {
    const response = await baseUrl.post("customer/subs/getById",{_id});
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const createCustomerWhileSendingMessageApi=async(customerDetails)=>{
try{
  const response=await baseUrl.post("/customer/create/message",customerDetails)
  console.log("res form mesage sned api call",response.data)
  if(response.data.isSuccess){
    return response.data;
  }
  else{
    return false;
  }
}
catch(error){
  console.log(error);
  return false;
}
}

export const updateCustomerLocationApi=async(_id,customerLocationDetails)=>{
  console.log(typeof customerLocationDetails.coords)
  console.log(_id)
  try{
    const response=await baseUrl.post("/customer/update/location",{_id,customerLocationDetails:{accuracy:customerLocationDetails.coords.accuracy,longitude:customerLocationDetails.coords.longitude,latitude:customerLocationDetails.coords.latitude}})
    console.log("res form mesage sned api call",response.data)
    if(response.data.isSuccess){
      return response.data;
    }
    else{
      return false;
    }
  }
  catch(error){
    console.log(error);
    return false;
  }
  }

  export const getAllRegisteredVehcleApi=async()=>{
    try{
      const res =await fetch(process.env.REACT_APP_VEHICLE_API)
      const response= await res.json();
    if(response.successful){
      return response.object;
    }
    else{
      return false;
    }
    }
    catch(error){
      console.log(error)
      return false;
    }
  }