import React, { Component } from "react";
import {
  CircularProgress,
  IconButton,
  TableRow,
  TableCell,
} from "@mui/material";
import { BiPencil } from "react-icons/bi";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default class VendorHistoryTableRow extends Component {
  constructor(props){
super(props);
this.state={
}
  }
  componentDidMount(){
  }
  render() {
    const {
      index,
      customer,
      onCreateNewVendorButtonPressed,
      onDeleteVendorButtonPressed,
      deleteLoading,
    } = this.props;
    return (
      <>
        <TableRow key={index} style={{ cursor: "pointer" }}>
          <TableCell component="th" scope="row" align="center">
            {index + 1}
          </TableCell>
          <TableCell align="center">{customer.name}</TableCell>
          <TableCell align="center" sx={{ position: "relative" }}>
            {customer.address ? customer.address : "-"}
          </TableCell>
          <TableCell align="center" sx={{ position: "relative" }}>
            {customer.key? customer.key : "--"}
          </TableCell>
          <TableCell align="center" sx={{ position: "relative" }}>
            {customer.createdAt ? customer.createdAt.slice(0,10) : "--"}
          </TableCell>
          <TableCell align="center" sx={{ position: "relative" }}>
            <IconButton
              onClick={() => {
                onCreateNewVendorButtonPressed("edit", customer);
              }}
            >
              <BiPencil />
            </IconButton>
            <IconButton
              sx={{ color: "red" }}
              onClick={() => {
                onDeleteVendorButtonPressed(customer._id);
              }}
            >
              {deleteLoading ? (
                <CircularProgress
                  sx={{
                    height: "23px!important",
                    width: "23px!important",
                    color: "#000",
                  }}
                />
              ) : (
                <DeleteOutlineIcon />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
      </>
    );
  }
}
