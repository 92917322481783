import React, { Component } from 'react'
import logo from "../../assets/logo.png"
import "./locationGetCus.css"
import Swal from 'sweetalert2'
import { updateCustomerLocationApi } from '../../Api';
import { useParams } from 'react-router-dom';

export  class LocationGetCusWrap extends Component {
  constructor(props){
    super(props);
    this.state={
      showLoading:true,
      success:false,
      error:false,
      loading:true,
    }
  }
  componentDidMount(){
    this.getLocationOnRender();
  }
  showPosition = async(position) => {
    if(position && this.props.userId){
      const res=await updateCustomerLocationApi(this.props.userId,position);
      console.log(position);
      console.log(res)
      if(res.isSuccess){
        Swal.fire({
          icon: "success",
          title: "Success",
          text:"Your location is fetched successfully. we will inform you about technician in a while.",
          showConfirmButton: true,
        });
        this.setState({showLoading:false,success:true,loading:false});
      }
      else{
        Swal.fire({
          icon: "error",
          title: "Location not found.",
          text:"something went wrong please try again later. or provide your location on whatsapp.",
          showConfirmButton: true,
        });
        this.setState({showLoading:false,success:false});
        this.setState({error:true})
      }
      
    }
    else{
      Swal.fire({
        icon: "error",
        title: "Location not found.",
        text: "This locaiton link is no more exist. please contace with owner.",
        showConfirmButton: true,
      });
      this.setState({showLoading:false,success:false});
      this.setState({error:true})
    }
    }
  getLocationOnRender = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition)
    }
    else {
      Swal.fire({
        icon: "error",
        title: "Location not found.",
        text:"something went wrong please try again later. or provide your location on whatsapp.",
        showConfirmButton: true,
      });
      this.setState({showLoading:false,success:false});
      this.setState({error:true})
    }

  }
  render() {
    return (
      <div className='loc-get'>
        <div class="card-load">
          <img src={logo} />
          {!this.state.error && <p class="perm-not">Please allow location permission to reach you.</p>}
          {!this.state.showLoading && this.state.error && <p class="notfound-not">location not found</p>}
          {this.state.showLoading && <p class="pleasewait">please wait...</p>}
          <div>
            <p>Location Taken By</p>
            <img src={logo} />
          </div>
        </div>
        <div class="card">
          <img src={logo} />
          {this.state.success &&<p>You have successfully submitted your breakdown location.</p>}
          {this.state.error &&<p>We are not able to fetch your location, Please try again with diffrenct device.</p>}
          {/* <button onclick="handleTabClose()">close</button>  */}
          <div>
            <p>Powered By</p>
            {/* <img src="./logo2.png" /> */}
          </div>
        </div>
      </div>
    )
  }
}

const LocationGetCus = () => {
  const {userId}=useParams();
  return (
    <><LocationGetCusWrap userId={userId}/></>
  )
}

export default LocationGetCus
